import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Box,
    Flex,
    Spacer,
    Divider,
    ButtonGroup,
    type BoxProps,
    type FlexProps,
    type ImageProps,
    type DividerProps,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { useColors } from "@mallardbay/lib-react-components"

import IconLogo from "~components/shared/header/icon-logo"
import ProfileButton from "~components/shared/header/profile-button"
import SidebarDrawerButton from "~components/shared/sidebar/sidebar-drawer-button"
import ResponsiveBlock from "~components/shared/responsive-block"
import { PAGE_HEADER_HEIGHT, TEST_IDS } from "~config/constants"
import CourierInbox from "~components/shared/courier-inbox"
import { useInAppBrowserContext } from "~config/context-providers/in-app-browser-provider"
import { usePageLayout } from "~config/context-providers/page-layout-provider"

export default function Header() {
    const styleProps = useStyleProps()
    const { isInAppBrowser } = useInAppBrowserContext()
    const { openSidebar } = usePageLayout()

    if (isInAppBrowser) {
        return null
    }

    return (
        <Box data-testid={TEST_IDS.PAGE_HEADER} {...styleProps.root}>
            <ResponsiveBlock>
                <Flex {...styleProps.flexContainer}>
                    <Link to="/">
                        <IconLogo {...styleProps.image} />
                    </Link>
                    <Spacer />
                    <Divider {...styleProps.divider} />
                    <ButtonGroup display="flex" alignItems="center" gap="10px">
                        <CourierInbox />
                        <ProfileButton />
                        <SidebarDrawerButton onClick={openSidebar} />
                    </ButtonGroup>
                </Flex>
            </ResponsiveBlock>
            <Divider />
        </Box>
    )
}

function useStyleProps() {
    const colors = useColors()
    const root: BoxProps = {
        top: 0,
        width: "100%",
        position: "sticky",
        zIndex: 5,
        transition: "all .2s",
        bgColor: colors.bgColor,
    }
    const flexContainer: FlexProps = {
        align: "center",
        justify: "space-between",
        py: 3,
        height: PAGE_HEADER_HEIGHT,
    }
    const image: ImageProps = {
        boxSize: "44px",
        display: ["flex", "flex", "none"],
    }
    const divider: DividerProps = {
        orientation: "vertical",
        height: "24px",
        mx: 4,
    }

    return { root, flexContainer, image, divider }
}
