import { faTrophy } from "@fortawesome/free-solid-svg-icons/faTrophy"
import { faPersonRifle } from "@fortawesome/free-solid-svg-icons/faPersonRifle"
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus"

import {
    AddOnCategory,
    FeatureKey,
    ListingType,
    BookingInternalSource,
} from "~graphql/generated/graphql"

import COPY from "./copy-constants"

export const DEFAULT_QUERY_LIMITS = {
    ONBOARDINGS: 10,
}

export const RTE_OPTIONS = {
    toolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
}

export const SCROLLABLE_CONTAINER = "SCROLLABLE_CONTAINER"

export const SUPPORT_CONTACTS = {
    PHONE: "+15129709123",
    EMAIL: "support@mallardbay.com",
}

export const LOCAL_STORAGE_KEYS = {
    MB_GLOBAL_OUTFITTER: "MB_GLOBAL_OUTFITTER",
    MB_IS_SIDEBAR_OPEN: "MB_IS_SIDEBAR_OPEN",
    USER_PREFERENCES: "USER_PREFERENCES",
}

export const allowedHTMLTags = [
    "p",
    "strong",
    "em",
    "u",
    "a",
    "li",
    "ul",
    "ol",
    "br",
]

export const TEST_IDS = {
    SIDEBAR_EMBEDDED: "SIDEBAR_EMBEDDED",
    SIDEBAR_DRAWER: "SIDEBAR_DRAWER",
    NUMBER_INPUT_FIELD: "NUMBER_INPUT_FIELD",
    PAGE_HEADER: "PAGE_HEADER",
    HUBSPOT_MENU_ITEM: "HUBSPOT_MENU_ITEM",
    GROUP_MEMBER_TAG: "GROUP_MEMBER_TAG",
    COLLAPSED_SECTION_BUTTON: "COLLAPSED_SECTION_BUTTON",
    ADD_ON_ITEM: "ADD_ON_ITEM",
    GROSS_BOOKINGS: "GROSS_BOOKINGS",
    OUTFITTER_STATS: "OUTFITTER_STATS",
    BOOKING_ACTIONS: "BOOKING_ACTIONS",
    EDITABLE_FIELDS_ICONS: "EDITABLE_FIELDS_ICONS",
    ADD_ONS_ICONS: "ADD_ONS_ICONS",
    BOOKING_INVITE_MODAL: "BOOKING_INVITE_MODAL",
    GENERIC_SELECT: "GENERIC_SELECT",
    NUMBER_OF_GUESTS_INPUT: "NUMBER_OF_GUESTS_INPUT",
    NUMBER_INPUT_INCREMENT: "NUMBER_INPUT_INCREMENT",
    NUMBER_INPUT_DECREMENT: "NUMBER_INPUT_DECREMENT",
    BOOKING_INVITE_EDITABLE_BUTTON: "BOOKING_INVITE_EDITABLE_BUTTON",
    MOBILE_DROPDOWN: "MOBILE_DROPDOWN",
    DESKTOP_DROPDOWN: "DESKTOP_DROPDOWN",
    EDIT_RATES_FORM: "EDIT_RATES_FORM",
    CANCEL_BUTTON: "CANCEL_BUTTON",
    IMPORT_BUTTON: "IMPORT_BUTTON",
    SEARCH_INPUT: "SEARCH_INPUT",
    RADIO_OPTION: "RADIO_OPTION_",
    LOADING_DOTS: "LOADING_DOTS",
    OUTFITTER_ACTIONS: "OUTFITTER_ACTIONS",
    SPINNER: "SPINNER",
    PREVIOUS_MONTH: "PREVIOUS_MONTH",
    NEXT_MONTH: "NEXT_MONTH",
    IMAGE_SKELETON: "IMAGE_SKELETON",
    IMAGE: "IMAGE",
    QR_CODE: "QR_CODE",
    PHONE_COUNTRY_SELECT: "phone-country-select",
    PHONE_NUMBER_INPUT: "phone-number-input",
    IS_HIDDEN_FROM_SPORTMEN_HELP_ICON: "IS_HIDDEN_FROM_SPORTMEN_HELP_ICON",
    ADD_QUANTITY_BUTTON: "ADD_QUANTITY_BUTTON",
    ADD_BUTTON: "ADD_BUTTON",
    REMOVE_BUTTON: "REMOVE_BUTTON",
    PREVIEW_BOX: "PREVIEW_BOX",
    ELLIPSIS_MENU: "ELLIPSIS_MENU",
    AMOUNT_INPUT: "AMOUNT_INPUT",
    RESPONSIBLE_PARTY_INPUT: "RESPONSIBLE_PARTY_INPUT",
    APPROVED_BY_INPUT: "APPROVED_BY_INPUT",
    STRIPE_REASON_INPUT: "STRIPE_REASON_INPUT",
    USER_REASON_INPUT: "USER_REASON_INPUT",
    STRIPE_PAYMENT_LINK_BUTTON: "STRIPE_PAYMENT_LINK_BUTTON",
    SUBSCRIPTION_PILL: "SUBSCRIPTION_PILL",
    COPY_INPUT: "COPY_INPUT",
    DROPZONE: "DROPZONE",
    SPECIES_SELECT: "SPECIES_SELECT",
    BOOKING_DATES_TRIGGER: "BOOKING_DATES_TRIGGER",
    PAGE_SIZE_SELECT: "PAGE_SIZE_SELECT",
    SORT_SELECT: "SORT_SELECT",
    PREV_PAGE_BUTTON: "PREV_PAGE_BUTTON",
    NEXT_PAGE_BUTTON: "NEXT_PAGE_BUTTON",
    MENU_SELECT_CONTROL_BUTTON: "MENU_SELECT_CONTROL_BUTTON",
    OUTFITTER_SELECT: "OUTFITTER_SELECT",
    LISTING_SELECT: "LISTING_SELECT",
    TABLE_PAGINATION: "TABLE_PAGINATION",
    REFERRAL_STATUS_SELECT: "REFERRAL_STATUS_SELECT",
    GUIDE_SELECT: "GUIDE_SELECT",
    GEAR_MENU_BUTTON: "GEAR_MENU_BUTTON",
    AVAILABILITY_BANNER: "AVAILABILITY_BANNER",
    SELECT_INPUT: "SELECT_INPUT",
    DELETE_HOSTED_VIDEO: "DELETE_HOSTED_VIDEO",
    HOSTED_VIDEO_URL: "HOSTED_VIDEO_URL",
    CLOSE_SIDE_DRAWER_BUTTON: "CLOSE_SIDE_DRAWER_BUTTON",
    TIME_BUTTON: "TIME_BUTTON",
    TIME_DISPLAY: "TIME_DISPLAY",
    ARCHIVED_FILTER_SELECT: "ARCHIVED_FILTER_SELECT",
    PAYWALL_ICON: "PAYWALL_ICON",
    SELECT_GUIDE_BUTTON: "SELECT_GUIDE_BUTTON",
    DELETE_BUTTON: "DELETE_BUTTON",
    EDIT_BUTTON: "EDIT_BUTTON",
    CONFIRMATION_ALERT_CONFIRM: "CONFIRMATION_ALERT_CONFIRM",
    LOCK_ICON: "LOCK_ICON",
    MENU_ITEM: "MENU_ITEM",
    BOOKING_INVITE_LISTING_PACKAGES_COLUMN:
        "BOOKING_INVITE_LISTING_OUTFITTER_COLUMN",
    BOOKING_INVITE_COUPON_COLUMN: "BOOKING_INVITE_COUPON_COLUMN",
    BOOKING_INVITE_NOTE_COLUMN: "BOOKING_INVITE_NOTE_COLUMN",
    BOOKING_INVITE_DATES_AND_GUESTS_COLUMN:
        "BOOKING_INVITE_DATES_AND_GUESTS_COLUMN",
    POPOVER_TRIGGER: "POPOVER_TRIGGER",
    ADD_ON_HOVER_ICON: "ADD_ON_HOVER_ICON",
    EDITABLE_FIELD_ICON: "EDITABLE_FIELD_ICON",
    BOOKING_INVITE_ADD_ONS_COLUMN: "BOOKING_INVITE_ADD_ONS_COLUMN",
    AUTHENTICATING_LOADER: "AUTHENTICATING_LOADER",
    OFFSITE_BOOKING_NOTES_INPUT: "OFFSITE_BOOKING_NOTES_INPUT",
    BANNER_CTA_LINK: "BANNER_CTA_LINK",
    RATES_MANAGE_SUBTEXT: "RATES_MANAGE_SUBTEXT",
    EDIT_PAYMENT_FEE_SETTINGS_FORM: "EDIT_PAYMENT_FEE_SETTINGS_FORM",
    PROFILE_BUTTON: "PROFILE_BUTTON",
    WEBSITE_RATE_INPUT: "WEBSITE_RATE_INPUT",
    MARKETPLACE_QUOTE_RATE_INPUT: "MARKETPLACE_QUOTE_RATE_INPUT",
    STANDARD_QUOTE_RATE_INPUT: "STANDARD_QUOTE_RATE_INPUT",
    WIDGET_RATE_INPUT: "WIDGET_RATE_INPUT",
    DASHBOARD_RATE_INPUT: "DASHBOARD_RATE_INPUT",
    LEFT_ICON: "LEFT_ICON",
    RIGHT_ICON: "RIGHT_ICON",
    IFRAME: "IFRAME",
    PROGRESS_COMPLETE: "PROGRESS_COMPLETE",
    PROGRESS_INCOMPLETE: "PROGRESS_INCOMPLETE",
    BOOKING_CARD: "BOOKING_CARD",
    BOOKING_DETAILS: "BOOKING_DETAILS",
    BOOKING_INVITE_DETAILS: "BOOKING_INVITE_DETAILS",
    LOCATION_PREVIEW_MAP: "LOCATION_PREVIEW_MAP",
    VIEW_CHECK_IN_SETTINGS: "VIEW_CHECK_IN_SETTINGS",
    PAYMENT_SETTINGS_VIEW: "PAYMENT_SETTINGS_VIEW",
    ADD_ONS_VIEW: "ADD_ONS_VIEW",
    LODGING_MODAL: "LODGING_MODAL",
    PRICING_PACKAGE_ITEM: "PRICING_PACKAGE_ITEM",
    ADD_ONS_PREVIEW: "ADD_ONS_PREVIEW",
    AVAILABILITY_MODAL: "AVAILABILITY_MODAL",
    AVAILABILITY_REVIEW_CALENDAR: "AVAILABILITY_REVIEW_CALENDAR",
    SELF_ONBOARD_NAVIGATION: "SELF_ONBOARD_NAVIGATION",
    ALL_SET_PAGE_HEADER: "ALL_SET_PAGE_HEADER",
    ALL_SET_PAGE_SUCCESS_ICON: "ALL_SET_PAGE_SUCCESS_ICON",
    HUBSPOT_CHAT_CLOSE_BUTTON: "HUBSPOT_CHAT_CLOSE_BUTTON",
    OUTFITTER_DOCUMENTS_PREVIEW: "OUTFITTER_DOCUMENTS_PREVIEW_",
    RESOURCE_TYPE_SELECT: "RESOURCE_TYPE_SELECT",
    CREATE_OUTFITTER_DOCUMENT_MENU: "CREATE_OUTFITTER_DOCUMENT_MENU",
    EDIT_OUTFITTER_DOCUMENT_MENU_BUTTON: "EDIT_OUTFITTER_DOCUMENT_MENU_BUTTON_",
    EDIT_OUTFITTER_DOCUMENT_MENU: "EDIT_OUTFITTER_DOCUMENT_MENU_",
    ASSIGN_ENTITIES_ELLIPSIS_MENU_BUTTON:
        "ASSIGN_ENTITIES_ELLIPSIS_MENU_BUTTON",
    ASSIGN_ENTITIES_ELLIPSIS_MENU: "ASSIGN_ENTITIES_ELLIPSIS_MENU",
}

export const PLACEHOLDER_IMG_SRC =
    "https://www.logistec.com/wp-content/uploads/2017/12/placeholder.png"

export const RESOURCES_URL = "https://resources.mallardbay.com/"

export const PRICING_INFO_KNOWLEDGE_BASE_PATH = "how-does-add-on-pricing-work"

export const UPLOAD_CARE_BASE_DOMAIN = "ucarecdn.com"

export const PAGE_HEADER_HEIGHT = "64px"

export const MOBILE_APP_URL =
    "https://apps.apple.com/us/app/guidetech-by-mallard-bay/id1665775548"

export const PAGES = {
    HOME: {
        name: "home",
        label: COPY.HOME,
    },
    INBOX: {
        name: "inbox",
        label: COPY.INBOX,
    },
    CALENDAR: {
        name: "calendar",
        label: COPY.CALENDAR,
    },
    LISTINGS: {
        name: "litings",
        label: COPY.LISTINGS,
    },
    BOOKINGS: {
        name: "bookings",
        label: COPY.BOOKINGS,
    },
    MY_OUTFITTER: {
        name: "my-outfitter",
        label: COPY.MY_OUTFITTER,
    },
    QUOTES: {
        name: "quotes",
        label: COPY.SEND_QUOTE,
    },
    REFERRALS: {
        name: "referrals",
        label: COPY.REFERRALS,
    },
    COUPONS: {
        name: "coupons",
        label: COPY.COUPONS,
    },
    REPORTS: {
        name: "reports",
        label: COPY.REPORTING.LABEL,
    },
    QUICK_PAY: {
        name: "quick-pay",
        label: COPY.QUICK_PAY.LABEL,
    },
}

export const ADMIN_PAGES = {
    ONBOARDINGS: {
        name: "admin-onboardings",
        label: COPY.ONBOARDINGS,
    },
    OUTFITTERS: {
        name: "admin-outfitters",
        label: COPY.OUTFITTERS,
    },
    AMENITIES: {
        name: "admin-amenities",
        label: COPY.AMENITIES,
    },
    SPECIES: {
        name: "admin-species",
        label: COPY.SPECIES,
    },
    LICENSES: {
        name: "admin-license",
        label: COPY.LICENSES,
    },
}

export const ROUTES = {
    HOME: "/",
    ONBOARD: "/onboard",
    TEAM_MEMBER_ONBOARDING: "/team-member-onboarding",
    REPORTING_PAYOUTS: "/reporting/payouts/",
    REPORTING_PAYMENTS: "/reporting/payments/",
    REPORTING_UNPAID_BALANCES: "/reporting/unpaid-balances/",
    REPORTING_BUSINESS_PERFORMANCE: "/reporting/business-performance",
    REPORTING: "/reporting",
    QUOTES: "/send-quote",
    BOOKINGS: "/my-bookings",
    CALENDAR: "/calendar",
    MANAGE_LISTINGS: "/manage-listings",
    AVAILABILITY_CALENDAR: "/calendar/edit",
    INBOX: "/inbox",
    ONBOARDINGS: "/onboardings",
    OUTFITTERS: "/outfitters",
    MY_OUTFITTER: "/my-outfitter",
    COUPONS: "/coupons",
    REFERRALS: "/referrals",
    QUICK_PAY: "/quick-pay",
    AMENITIES: "/admin/assets/amenities",
    SPECIES: "/admin/assets/species",
    LICENSES: "/admin/assets/license-infos",
    EDIT_SUBSCRIPTION: "/edit-subscription",
    ALL_SET: "/all-set",
    ALL_SET_ONBOARD: "/all-set/onboard",
    SELF_ONBOARD: "/self-onboard/",
}

export const USER_ROLE_LABELS = {
    ADMIN: "Admin",
    OUTFITTER: "Outfitter",
    USER: "User",
}

export const PROFILE_MENU_LABELS = {
    VIEW_AS_LABEL: "View as...",
    LOG_OUT_LABEL: "Log out",
    ACCOUNT_SETTINGS: "Account settings",
    CHAT_WITH_SUPPORT: "Chat with support",
    REQUEST_SUBSCRIPTION_CHANGES: "Request subscription changes",
}

export const HUBSPOT_INLINE_EMBED_ELEMENT_ID =
    "hubspot-conversations-inline-embed-selector"

export const ONE_MEGABYTE_IN_BYTES = 1_048_576 // 1024 * 1024

export const TYPE_NAMES = {
    ADDRESS: "Address",
    REMAINING_PAYMENT: "RemainingPayment",
    PAYMENT_BLOCK: "PaymentBlock",
    BANNER: "Banner",
    BANNER_CONTENT: "BannerContent",
    CALL_TO_ACTION: "CallToAction",
    BOOKING: "Booking",
    QUOTE_PACKAGE: "QuotePackage",
    QUOTE_ADD_ON: "QuoteAddOn",
    OUTFITTER: "Outfitter",
    QUOTE_ITEM: "QuoteItem",
    DENIAL_REASON: "DenialReason",
    AVAILABILITY: "Availability",
    GENERIC_AVAILABILITY_ENTRY: "GenericAvailabilityEntry",
    GROUP_PAY_SHARE: "GroupPayShare",
    SLOT_SHARE: "SlotShare",
    GROUP_PAY_MEMBER: "GroupPayMember",
    PAYMENT: "Payment",
    PAYMENT_REQUEST: "PaymentRequest",
    PAYMENT_ITEM_REQUEST: "PaymentItemRequest",
    TEAM_MEMBER: "TeamMember",
    USER: "User",
    VALUE_CHANGE: "ValueChange",
    LISTING: "Listing",
    PRICING_PACKAGE: "PricingPackage",
    BOOKING_INVITE: "BookingInvite",
    BOOKING_INVITE_EDITABLE_FIELDS: "BookingInviteEditableFields",
    ADD_ON: "AddOn",
    COUPON: "Coupon",
    DEAL_ATTRIBUTION_OPTION: "DealAttributionOption",
    ADDITIONAL_PAYMENT_SUMMARY: "AdditionalPaymentSummary",
    PAYOUT: "Payout",
    PAYOUT_TRANSACTION: "PayoutTransaction",
    PAYOUT_TIMELINE: "PayoutTimeline",
    OUTFITTER_SLUG: "OutfitterSlugResult",
    SPECIES: "Species",
    CALENDAR_SUBSCRIPTION: "CalendarSubscription",
    LICENSE_INFO: "LicenseInfo",
    BOOKING_REFERRAL: "BookingReferral",
    LINE_ITEM: "LineItem",
    CANCELLATION_POLICY: "CancellationPolicy",
    OUTFITTER_CANCELLATION_POLICY: "OutfitterCancellationPolicy",
    BOOKING_CANCELLATION_MEMBER_INFO: "BookingCancellationMemberInfo",
    BOOKING_CANCELLATION_INFO: "BookingCancellationInfo",
    BOOKING_PAYMENT_SUMMARY: "BookingPaymentSummary",
    MEMBER_PAYMENT_SUMMARY: "MemberPaymentSummary",
    PAYMENT_SETTINGS: "PaymentSettings",
    PAYMENT_RETRY: "PaymentRetry",
    STRIPE_SETUP_INTENT: "StripeSetupIntent",
    STRIPE_PAYMENT_INTENT: "StripePaymentIntent",
    PAYMENT_SUMMARY: "PaymentSummary",
    QUOTE_PRICE: "QuotePrice",
    CHECK_IN_CONFIG: "CheckInConfig",
    ADDITIONAL_BOOKING_PAYMENT_SUMMARY: "AdditionalBookingPaymentSummary",
    FEATURE_ACCESS: "FeatureAccess",
    FEATURE_ACCESS_SETTING: "FeatureAccessSetting",
    ONBOARDING: "Onboarding",
    ONBOARDING_RESULTS: "OnboardingQueryResults",
    ONBOARDING_SUBSCRIPTION_OPTIONS: "OnboardingSubscriptionOptions",
    ID_AND_NAME: "IdAndName",
    OUTFITTER_SUBSCRIPTION_INFO: "OutfitterSubscriptionInfo",
    OUTFITTER_FEATURE_ACCESS_INFO: "OutfitterFeatureAccessInfo",
    LISTING_PROGRESS: "ListingProgress",
    LODGING: "Lodging",
    TRIP_ITINERARY: "TripItinerary",
    TRIP_ITINERARY_ITEM: "TripItineraryItem",
    RESOURCE: "Resource",
    OUTFITTER_DOCUMENT: "OutfitterDocument",
    WIDGET: "Widget",
} as const

// TODO: make generic
export const ADD_ON_ICON_MAP = {
    [AddOnCategory.Trophy]: faTrophy,
    [AddOnCategory.GunRental]: faPersonRifle,
    [AddOnCategory.MealUpgrade]: faUtensils,
    [AddOnCategory.Other]: faCirclePlus,
} as const

export const BOOKING_INVITE_STEPS = {
    LISTING: "LISTING",
    DATES: "DATES",
    PACKAGE: "PACKAGE",
    GUESTS_AND_ADD_ONS: "GUESTS_AND_ADD_ONS",
    SUMMARY: "SUMMARY",
    ATTRIBUTION: "ATTRIBUTION",
}

export const NEW_BOOKING_STEPS = {
    LISTING: "LISTING",
    TRIP_DETAILS: "TRIP_DETAILS",
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
    GUESTS_AND_PACKAGE: "GUESTS_&_PACKAGE",
    PAYMENT_DETAILS: "PAYMENT_DETAILS",
}

export const RESOURCE_TYPE = {
    LISTING: "LISTING",
    LODGING: "LODGING",
    VEHICLE: "VEHICLE",
    BOAT: "BOAT",
    ATV: "ATV",
    GEAR: "GEAR",
    BLIND: "BLIND",
}

export const HEIGHT_LESS_HEADER = "calc(100vh - 72px)"

export const BUTTON_TYPES = {
    BUTTON: "button",
    SUBMIT: "submit",
} as const

export const BUTTON_VARIANTS = {
    UNSTYLED: "unstyled",
} as const

export const VALIDATION_MESSAGES = {
    OPTION_REQUIRED: "Please select an option",
    PACKAGE_NAME_REQUIRED: "Please provide a package name",
    PACKAGE_PRICE_REQUIRED: "Please provide a price",
    VALID_PRICE: "Please enter a valid price",
    GUESTS_MINIMUM: "Please include at least 1 guest",
    MIN_GUESTS_LESS_THAN_MAX_GUESTS: "Min guests must be less than max guests",
    PACKAGE_GUESTS_ARE_THE_SAME:
        "Select group package type if minimum and maximum guests are the same",
    DEPOSIT_AMOUNT: "Please enter an amount",
    DEPOSIT_PERCENTAGE: "Please enter a percentage",
    DEPOSIT_TYPE: "Please select a deposit type",
    DAYS_BEFORE_TRIP_BALANCE_DUE: "Please enter a number of days",
    ALLOW_DEPOSIT: "Please select an option",
    STATEMENT_DESCRIPTOR_REQUIRED: "Please enter a statement descriptor",
    STATEMENT_DESCRIPTOR_TOO_SHORT:
        "Statement descriptor must be at least 5 characters",
    STATEMENT_DESCRIPTOR_TOO_LONG:
        "Statement descriptor must be at most 22 characters",
    DAYS_BEFORE_TRIP_DISABLE_PAYMENT_PLAN:
        "Please enter a number of days before payment plan is disabled",
} as const

export const FORM_MODES = {
    ALL: "all",
} as const

export enum CustomHeaders {
    CLIENT_NAME = "x-client-name",
    CLIENT_VERSION = "x-client-version",
}

export enum Meridiem {
    AM = "AM",
    PM = "PM",
}

export const CLIENT_TYPE = "dashboard"

export const NO_SELECTION_VALUE_FOR_SELECT = ""

export enum ListingStatus {
    IN_PROGRESS = "In progress",
    UNLISTED = "Unlisted",
    LISTED = "Listed",
}

export enum BookingInviteQuoteRates {
    Standard = "STANDARD",
    Marketplace = "MARKETPLACE",
}

export enum CardTagStatus {
    Pending = "PENDING",
    Accepted = "ACCEPTED",
}

export const BookingInviteQuoteRatesToInternalSource = {
    [BookingInviteQuoteRates.Standard]: BookingInternalSource.SendQuote,
    [BookingInviteQuoteRates.Marketplace]:
        BookingInternalSource.SendQuoteMarketplace,
}

export const INTERNAL_SOURCE = "SendQuote"

export const QUERY_STRING_PROPS = {
    BOOKING_INVITE_ID: "booking_invite_id",
    INTERNAL_SOURCE: "is",
    INTERNAL_SOURCE_ENTITY_ID: "isei",
    INTERNAL_SOURCE_ENTITY_TYPE: "iset",
    SOURCE: "source",
}

export const OUTFITTER_SENT_QUOTE_DEAL_ATTRIBUTION_ID = "outfitter_sent_quote"

export const CARD_BRANDS = {
    VISA: "visa",
    MASTERCARD: "mastercard",
    DISCOVER: "discover",
    AMEX: "amex",
} as const

export const HTTP = "http://"
export const HTTPS = "https://"

export const MAX_STATEMENT_DESCRIPTOR_LENGTH = 22
export const MIN_STATEMENT_DESCRIPTOR_LENGTH = 5

export const TABLE_SEARCH_PARAMS = {
    SORT: "sort",
    PAGE: "page",
    PAGE_SIZE: "pageSize",
    SEARCH: "search",
} as const

export const REFERRAL_ID_SEARCH_PARAM = "referral_id"

export const DEFAULT_PAGE_SIZE = "10"
export const DEFAULT_PAGE_NUMBER = "1"

export const LISTING_DEFAULTS = {
    type: ListingType.Hunting,
    min_guest: 1,
    max_guest: 6,
    license_required: false,
    lodgings: [],
    photos: [],
}

export const LISTING_MIN_GUESTS = 1
export const LISTING_MAX_GUESTS = 99
export const LISTING_MAX_TITLE_LENGTH = 250

export const VALIDATION_MODE = {
    ON_BLUR: "onBlur",
    ON_CHANGE: "onChange",
    ON_SUBMIT: "onSubmit",
    ON_TOUCH: "onTouched",
    ALL: "all",
} as const

export enum BookingMetricDateProp {
    CREATED_AT = "created_at",
    START_DATE = "start_date",
}

export enum MetricDateRange {
    THIS_YEAR = "this_year",
    LAST_YEAR = "last_year",
    NEXT_YEAR = "next_year",
}

export const DATE_FORMATS = {
    MONTH_3_LETTER: "MMM",
    MONTH_3_LETTER_YEAR: "MMM, yyyy",
    REDEEM_BY: "LL / dd / yyyy",
    EDIT_TIMESTAMP: "iiii, MMMM d, yyyy 'at' h:mm a",
}

export const IS_IN_APP_BROWSER_QUERY_PARAM = "in_app"

export const CURRENCY_LOCALE = {
    US: "en-US",
}

export const ONBOARD_STEPS = {
    OUTFITTER: 1,
    LISTING: 2,
    STRIPE: 3,
    DONE: 4,
}

export const URL_PARAMS = {
    RESOURCE_ID: "resource_id",
    RESOURCE_TYPE: "resource_type",
    AMOUNT: "amount",
}

export const HUBSPOT_URL = "https://app.hubspot.com"

export const NOON_IN_MINUTES = 720

export const MIDNIGHT_IN_MINUTES = 1440

export const AUTO_GENERATED_EMAIL_PREFIX = "offsite.booking+"
export const EMAIL_DOMAIN = "@mallardbay.com"

export const CUSTOM_PRICING_PACKAGE_NAME = "No Package name"

export const ONBOARDING_CALL_URL =
    "https://meetings.hubspot.com/bennett-krampe?uuid=8c5fc3ae-8dcf-4c12-a632-e95dca0df3a8"

export const ADDITIONAL_PAYMENT_STEPS = {
    USER_AND_ADDONS: "USER_AND_ADDONS",
    PAYMENT_DETAILS: "PAYMENT_DETAILS",
}

export const LARGEST_MODAL_SIZE = "6xl"

// TODO: Move to backend and return in the outfitterFeatureAccessInfo query.
export const PAYWALL_FEATURES_WITH_LIMITS = [
    FeatureKey.TeamMembers,
    FeatureKey.Coupons,
]

export const TABLE_MODAL_SIZE = "5xl"

export const COLOR_PICKER_PALETTE = [
    "#34ACCB",
    "#3461CB",
    "#34CB9F",
    "#9F34CB",
    "#5434CB",
    "#CB5334",
    "#CB9F34",
    "#CB3461",
    "#60CB34",
    "#34CB53",
    "#ACCB34",
]

export const WHITE_HEX = "#FFFFFF"
export const BLACK_HEX = "#000000"

export const FEATURE_KNOWLEDGE_CTA_URL_PATHS = {
    BOOKINGS: "outfitter-success#manage-bookings",
    CALENDAR: "how-do-i-change-my-availability-on-mallard-bay",
    QUOTES: "how-do-i-send-a-quote-to-a-potential-client",
    REPORTING: "how-do-i-use-the-reporting-tool",
    QUICK_PAY: "quick-pay",
    LISTING: "how-do-i-edit-my-outfitter/charter-info",
    OUTFITTER: "how-do-i-edit-my-outfitter/charter-info",
    OUTFITTER_SUCCESS: "outfitter-success",
}

export const EVENT_PADDING = {
    SM: 1,
    MD: 5,
} as const

export const EDITABLE_LISTING_SECTIONS = {
    BASICS: "basics",
    PHOTOS: "photos",
    LOCATION: "location",
    SPECIES: "species",
    AMENITIES: "amenities",
    GUIDE: "guide",
    CHECK_IN_SETTINGS: "check-in-settings",
}

export const EDITABLE_OUTFITTER_SECTIONS = {
    PHOTOS: "photos",
    ADMIN_NOTES: "admin_notes",
    VIDEO: "video",
    REVIEW: "review",
    CHECK_IN_SETTINGS: "check_in_settings",
    CANCELLATION_POLICY: "cancellation_policy",
    ADMIN_ACTIONS: "admin_actions",
}

export const DASHBOARD_BOOKING_SOURCES = {
    BOOKING_SCREEN: "booking_screen",
    CALENDAR_SCREEN: "calendar_screen",
}

export const OUTFITTER_ONBOARDING_STEPS = {
    NAME: "OUTFITTER_ONBOARDING_NAME_STEP",
    ADDITIONAL_INFO: "OUTFITTER_ONBOARDING_ADDITIONAL_INFO_STEP",
    CHECK_IN: "OUTFITTER_ONBOARDING_CHECK_IN_STEP",
    DESCRIPTION: "OUTFITTER_ONBOARDING_DESCRIPTION_STEP",
    MEDIA: "OUTFITTER_MEDIA_STEP",
    CANCELLATION_POLICY: "OUTFITTER_CANCELLATION_POLICY_STEP",
}

export const PAGE_HEADER_MARGIN_TOP = 3

export enum CouponType {
    Percent = "percent",
    Value = "value",
}

export const MALLARD_BAY_WEBSITE_LABEL = "mallardbay.com"

export const RESOURCE_DEFAULTS = {
    YEAR: 1900,
    CAPACITY: 1,
}

export const HTTP_PREFIX = "http://"
export const HTTPS_PREFIX = "https://"
