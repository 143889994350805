import type { PropsWithChildren } from "react"
import React, { useEffect } from "react"

import { useAuth } from "~utils/hooks/use-auth"
import { getFullRoute } from "~utils/helpers/navigation-helpers"
import AuthenticatingLoader from "~components/shared/loader/authenticating-loader"

const SIGNUP_SCREEN_HINT = "signup"

interface Props extends PropsWithChildren {
    readonly isSignupDefault?: boolean
}

export default function WithAuthentication({
    isSignupDefault,
    children,
}: Props) {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth()

    const screenHint = isSignupDefault ? SIGNUP_SCREEN_HINT : undefined

    useEffect(() => {
        if (isLoading || isAuthenticated) return

        void loginWithRedirect({
            appState: { returnTo: getFullRoute() },
            authorizationParams: {
                screen_hint: screenHint,
            },
        })
    }, [isLoading, isAuthenticated])

    return isAuthenticated && !isLoading ? children : <AuthenticatingLoader />
}
