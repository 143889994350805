import React, { useEffect } from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Divider } from "@chakra-ui/react"
import { componentStyles, Box } from "@mallardbay/lib-react-components"

import SidebarEmbedded from "~components/shared/sidebar/sidebar-embedded"
import SidebarDrawer from "~components/shared/sidebar/sidebar-drawer"
import { usePageLayout } from "~config/context-providers/page-layout-provider"
import { useIsTablet } from "~utils/hooks/use-is-tablet"
import { useIsEitherCalendarPage } from "~utils/hooks/route-helpers"

export default function Sidebar() {
    const styles = useStyles()

    const { isMobile, closeSidebar } = usePageLayout()
    const shouldAutoCollapseNav = useShouldAutoCollapseNav()

    useEffect(() => {
        if (shouldAutoCollapseNav) {
            closeSidebar()
        }
    }, [shouldAutoCollapseNav])

    return (
        <>
            {!isMobile && (
                <Box style={styles.inlineSidebarContainer}>
                    <SidebarEmbedded />
                    <Divider orientation="vertical" />
                </Box>
            )}
            {isMobile && <SidebarDrawer />}
        </>
    )
}

function useStyles() {
    return componentStyles({
        inlineSidebarContainer: {
            position: "sticky",
            top: 0,
            left: 0,
            display: { base: "none", md: "flex" },
            height: "100vh",
            flexDirection: "row",
            justifyContent: "space-between",
        },
    })
}

function useShouldAutoCollapseNav() {
    // Auto collapse nav bar on the calendar page for tablets to default to a better calendar view
    const isCalendarRoute = useIsEitherCalendarPage()
    const isTablet = useIsTablet()
    return isCalendarRoute && isTablet
}
