import type { PropsWithChildren } from "react"
import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useMatch } from "@reach/router"
import {
    componentStyles,
    Box,
    VerticalStack,
} from "@mallardbay/lib-react-components"

import useAnalytics from "~utils/analytics-hooks/use-analytics"
import Header from "~components/shared/header/header"
import ImpersonationBox from "~components/shared/auth/impersonation-box"
import useOnboardingRedirects from "~utils/hooks/use-onboarding-redirects"
import { ROUTES, SCROLLABLE_CONTAINER } from "~config/constants"
import Sidebar from "~components/shared/sidebar/sidebar"
import useSidebarInlineWidth from "~components/shared/sidebar/use-sidebar-inline-width"

export default function PageLayout({ children }: PropsWithChildren) {
    const doesNeedFullScreen = useDoesItNeedFullScreen()

    const styles = useStyles()

    useAnalytics()

    const { isRedirecting } = useOnboardingRedirects()

    // Prevents the screen from flickering durect redirects.
    if (isRedirecting) return null

    // Handle routes that need full screen
    if (doesNeedFullScreen) {
        return <Box style={styles.fullScreenContainer}>{children}</Box>
    }

    return (
        <Box style={styles.pageLayoutContainer}>
            <Sidebar />
            <Box style={styles.bodyContainer}>
                <VerticalStack style={styles.verticalStack}>
                    <Header />
                    <Box id={SCROLLABLE_CONTAINER} style={styles.bodyContent}>
                        {children}
                    </Box>
                    <ImpersonationBox />
                </VerticalStack>
            </Box>
        </Box>
    )
}

function useDoesItNeedFullScreen() {
    const isTeamMemberOnboarding = useMatch(ROUTES.TEAM_MEMBER_ONBOARDING)
    const isOnboardingRoute = useMatch(ROUTES.ONBOARD)
    const isSelfOnboardAllSetPage = useMatch(ROUTES.ALL_SET_ONBOARD)
    return (
        isTeamMemberOnboarding || isOnboardingRoute || isSelfOnboardAllSetPage
    )
}

function useStyles() {
    const sidebarWidth = useSidebarInlineWidth()

    const bodywidth = `calc(100% - ${sidebarWidth})`

    return componentStyles({
        pageLayoutContainer: {
            width: "100vw",
            height: "100vh",
            position: "absolute",
            display: "flex",
        },
        bodyContainer: {
            height: "100%",
            width: bodywidth,
            overflowX: "hidden",
        },
        verticalStack: {
            height: "100%",
            overflowY: "hidden",
            gap: 0, //Override the lib default otherwise we end up with extra padding between header and content
        },
        fullScreenContainer: {
            width: "100vw",
            height: "100vh",
        },
        bodyContent: {
            height: "100%",
            width: "100%",
            overflowY: "scroll",
        },
    })
}
