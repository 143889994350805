import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Text, Tooltip, useColorModeValue, HStack } from "@chakra-ui/react"
import { Link } from "gatsby"
import { useMatch } from "@reach/router"
import {
    Box,
    componentStyles,
    ExternalLink,
} from "@mallardbay/lib-react-components"
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"

import { usePageLayout } from "~config/context-providers/page-layout-provider"

import { useSharedStyles, useTooltipProps } from "./sidebar-styles"
import SidebarIcon from "./sidebar-icon"
import SidebarPaywallIndicator from "./sidebar-paywall-indicator"

interface Props {
    readonly label: string
    readonly link: string
    readonly icon?: IconDefinition
    readonly isSidebarCollapsed: boolean
    readonly isPaywalled?: boolean
    readonly isExternalLink?: boolean
}

export default function SidebarItem({
    label,
    link,
    icon,
    isSidebarCollapsed,
    isPaywalled,
    isExternalLink,
}: Props) {
    const isActiveItem = Boolean(useMatch(link === "/" ? link : `${link}/*`))
    const styles = useStyleProps({ isSidebarCollapsed, isActiveItem })
    const tooltipProps = useTooltipProps()
    const sharedStyles = useSharedStyles({ isActiveItem })
    const LinkComponent = isExternalLink ? ExternalLink : Link
    const { closeSidebarIfMobile } = usePageLayout()

    return (
        <LinkComponent
            to={link}
            href={link}
            isUnderlined={false}
            style={styles.link}
            onClick={closeSidebarIfMobile}
        >
            <Tooltip
                {...tooltipProps}
                label={label}
                isDisabled={!isSidebarCollapsed}
            >
                <Box style={styles.accordionContainer}>
                    <HStack style={styles.labelRow}>
                        {isActiveItem && <Box style={sharedStyles.activeBar} />}
                        <SidebarIcon
                            icon={icon}
                            isActiveItem={isActiveItem}
                            isPaywalled={isPaywalled}
                            isSidebarCollapsed={isSidebarCollapsed}
                        />
                        {!isSidebarCollapsed && (
                            <Text sx={sharedStyles.label}>{label}</Text>
                        )}
                    </HStack>
                    {isPaywalled && !isSidebarCollapsed && (
                        <SidebarPaywallIndicator />
                    )}
                </Box>
            </Tooltip>
        </LinkComponent>
    )
}

function useStyleProps({
    isSidebarCollapsed,
    isActiveItem,
}: {
    isSidebarCollapsed?: boolean
    isActiveItem?: boolean
}) {
    // TODO move this color to useColors
    const bgColor = useColorModeValue("gray.100", "gray.800")

    return componentStyles({
        labelRow: { gap: 0 },
        accordionContainer: {
            flex: "1",
            textAlign: "left",
            paddingY: 1,
            paddingX: 2,
            width: "100%",
            display: "flex",
            position: "relative",
            alignItems: "center",
            justifyContent: isSidebarCollapsed ? "center" : "space-between",
            bgColor: isActiveItem ? bgColor : "initial",
            transition: "all .2s",
            ":hover": {
                backgroundColor: bgColor,
            },
        },
        link: {
            _hover: { textDecoration: "none" },
        },
    })
}
