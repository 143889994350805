import { useSelfOnboardNavigation } from "~components/self-onboard/hooks/use-self-onboard-navigation"
import { useOutfitterContext } from "~config/context-providers/outfitter-provider"
import type { UpdateOutfitterInput } from "~graphql/generated/graphql"
import useOutfitter from "~utils/hooks/use-outfitter"
import { useUpdateOutfitter } from "~utils/hooks/use-update-outfitter"

export function useStepOutfitter() {
    const { outfitter: persistedOutfitter } = useOutfitterContext()
    const { outfitter, isLoading: isOutfitterLoading } = useOutfitter({
        id: persistedOutfitter?.id,
        fetchPolicy: "cache-first",
    })

    return { outfitter, isOutfitterLoading }
}

export function useUpdateOutfitterForStep({
    updatedSection,
}: {
    updatedSection: string
}) {
    const { outfitter, isOutfitterLoading } = useStepOutfitter()

    const { handleNextClick } = useSelfOnboardNavigation({
        isMainNavigation: false,
    })

    const { updateOutfitter: updateOutfitterMutation } = useUpdateOutfitter({
        outfitterId: outfitter?.id || "",
        onCompleted: () => {
            handleNextClick?.()
        },
        updatedSection,
    })

    const updateOutfitter = async (values: UpdateOutfitterInput) => {
        await updateOutfitterMutation(values)
    }

    return {
        updateOutfitter,
        outfitter,
        isOutfitterLoading,
    }
}
