import queryString from "query-string"
import slugify from "slugify"
import { navigate } from "gatsby"

import { version } from "~package"
import type {
    LicenseInfoFieldsFragment,
    AmenityFieldsFragment,
    MinimalOutfitterFieldsFragment,
    ListingFieldsFragment,
    UserFieldsFragment,
    OutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import { OutfitterStatus } from "~graphql/generated/graphql"
import type { NumberFields } from "~utils/types"
import { MAX_STATEMENT_DESCRIPTOR_LENGTH } from "~config/constants"

// TODO move away from impoting package
export function getVersionNumber() {
    return version
}

export function sortAmenitiesByName(
    amenityA: AmenityFieldsFragment,
    amenityB: AmenityFieldsFragment
): number {
    if (amenityA.name > amenityB.name) return 1
    if (amenityA.name < amenityB.name) return -1
    return 0
}

export function sortLicensesByState(
    licenseA: LicenseInfoFieldsFragment,
    licenseB: LicenseInfoFieldsFragment
): number {
    if (licenseA.state > licenseB.state) return 1
    if (licenseA.state < licenseB.state) return -1
    return 0
}

export function filterApprovedOutfitters(
    outfitters: MinimalOutfitterFieldsFragment[]
): MinimalOutfitterFieldsFragment[] {
    return outfitters.filter(
        (outfitter) => outfitter.status === OutfitterStatus.Approved
    )
}

export function filterPublishedListings(
    listings: ListingFieldsFragment[]
): ListingFieldsFragment[] {
    return listings.filter((listing) => listing.published)
}

export function filterListingsWithoutAvailability(
    listings: ListingFieldsFragment[]
): ListingFieldsFragment[] {
    return listings.filter((listing) => !listing.next_availability)
}

export function filterListingsByOutfitterId({
    listings,
    filterByOutfitterId,
}: {
    listings: ListingFieldsFragment[]
    filterByOutfitterId: string
}) {
    return listings.filter(
        (listing) => listing.outfitter.id === filterByOutfitterId
    )
}

function getUrlQueryParamsObj(): queryString.ParsedQuery {
    if (isServerSide()) {
        return {}
    }
    const { search } = window.location
    return queryString.parse(search)
}

export function navigateToSearchParams<
    TParams extends Record<string, string | number>,
>(newParams: TParams) {
    const queryParams = getUrlQueryParamsObj()

    const params = queryString.stringify({
        ...queryParams,
        ...newParams,
    })

    void navigate(`?${params}`, { replace: true })
}

export function getUrlQueryParamInt(
    paramName: string,
    defaultParamValue: string
) {
    const param = getUrlQueryParam(paramName)

    return parseInt(param || defaultParamValue, 10)
}

export function getUrlQueryParam(paramName: string) {
    const paramsObj = getUrlQueryParamsObj()

    const param = paramsObj[paramName]
    const isParamValid = typeof param === "string"

    return isParamValid ? param : null
}

export function getBooleanUrlQueryParam(paramName: string) {
    const param = getUrlQueryParam(paramName)

    return param === "true"
}

function isServerSide() {
    return typeof window === "undefined"
}

// Adds or removes an item from an array depending on if it exists (or passes custom bool check)
export const adjustArrayWithItem = <T>(
    array: T[],
    item: T,
    boolCheck?: (item: T) => boolean
): T[] => {
    if (
        boolCheck
            ? array.some((arrayItem) => boolCheck(arrayItem))
            : array.includes(item)
    ) {
        return array.filter((arrayItem) =>
            boolCheck ? !boolCheck(arrayItem) : arrayItem !== item
        )
    }

    return [...array, item]
}

// Removes duplicate items from an array, pass in a field for objects (usually id)
export const dedupeArray = <T>(
    arrayToDedupe: T[],
    fieldToCheck?: keyof T
): T[] => {
    if (fieldToCheck) {
        return arrayToDedupe.filter(
            (item, idx, array) =>
                idx ===
                array.findIndex(
                    (otherItem) =>
                        otherItem[fieldToCheck] === item[fieldToCheck]
                )
        )
    }
    return arrayToDedupe.filter(
        (item, idx, array) => idx === array.indexOf(item)
    )
}

// Sorts an array by the created_at field, with the newest first
export const sortNewestLast = <T extends { created_at: number }>(
    array: T[] = []
): T[] => [...array].sort((itemA, itemB) => itemA.created_at - itemB.created_at)

export const getUTCNoon = (date: Date): number => {
    return Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        12,
        0,
        0
    )
}

export const sortAscOrDesc = <T extends Record<string, unknown>>(
    array: T[],
    field: NumberFields<T>,
    isAsc = false
): T[] =>
    [...array].sort((itemA, itemB) => {
        const valueA = itemA[field]
        const valueB = itemB[field]

        // Ensure the values are numbers
        if (typeof valueA !== "number" || typeof valueB !== "number") {
            return 0
        }

        return isAsc ? valueA - valueB : valueB - valueA
    })

// Updating item at array in index
export const updateItemAtIndex = <T>(
    array: T[],
    item: T,
    index: number
): T[] => {
    return [...array.slice(0, index), item, ...array.slice(index + 1)]
}

export const getUserName = (
    user: UserFieldsFragment | null | undefined
): string => `${user?.first_name} ${user?.last_name}`

export function getArrayOfCount(length: number): number[] {
    return Array.from({ length })
        .fill(null)
        .map((_, index) => index)
}

export function splitArrayAtIndex<TItem>(array: TItem[], breakAtIndex: number) {
    return {
        first: array.slice(0, breakAtIndex),
        second: array.slice(breakAtIndex),
    }
}

export function isNil(value: unknown): value is null | undefined {
    return value === null || value === undefined
}

export function groupByKey<TItem, K extends keyof TItem>(
    array: TItem[],
    key: K
): Record<string, TItem[]> {
    return array.reduce<Record<string, TItem[]>>(
        (groupedObject, currentItem) => {
            const currentKey = String(currentItem[key])

            if (!groupedObject[currentKey]) {
                groupedObject[currentKey] = []
            }

            groupedObject[currentKey].push(currentItem)

            return groupedObject
        },
        {}
    )
}

// Used to conditionally spread objects for updates / creates
// Replaces ...(value ? { key: value } : {})
export function ifDefined(
    obj: Record<string, unknown>
): Record<string, unknown> {
    return Object.fromEntries(
        Object.entries(obj).filter(([, value]) => {
            return value !== undefined
        })
    )
}

export async function getAndOpenPdfInNewTab({
    getPdfFunc,
}: {
    getPdfFunc: () => Promise<string | null>
}) {
    const pdf = await getPdfFunc()

    if (!pdf) return

    openPdfInNewTab(pdf)
}

function openPdfInNewTab(base64: string) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix eslint
    const buffer = Buffer.from(base64 ?? "", "base64")
    const blob = new Blob([buffer], {
        type: "application/pdf",
    })
    const objectUrl = URL.createObjectURL(blob)

    // There's another method using window.open, but we need to use
    // the anchor element trick instead to avoid safari detecting this
    // as a popup and blocking it.
    const anchorElement = document.createElement("a")
    anchorElement.href = objectUrl
    anchorElement.target = "_blank"
    anchorElement.click()
}

// matches servers implementation of slugify for outfitter names
export function slugifyOutfitterName(name: string) {
    return slugify(name, {
        replacement: "-",
        remove: /[*+~.()'"!:@]/g,
        locale: "en",
        lower: true,
        strict: true,
    })
}

export function omit<TObject extends object>(
    object?: TObject | null,
    keysToOmit: (keyof TObject)[] = []
) {
    if (!object) return undefined

    const objectCopy = { ...object }
    keysToOmit.forEach((key) => delete objectCopy[key])

    return objectCopy
}

// remove any keys that are not in the entity
// also remove any keys that have not changed from the entities values
export function getCleanedUpdateInput<
    TEntity extends Record<string, unknown>,
    TUpdateInput extends Record<string, unknown>,
>({ entity, updateInput }: { entity: TEntity; updateInput: TUpdateInput }) {
    const entityKeys = Object.keys(entity)
    const updateInputKeys = Object.keys(updateInput)

    return updateInputKeys.reduce((cleanedUpdateInput, key) => {
        if (!entityKeys.includes(key)) {
            return cleanedUpdateInput
        }

        const entityValue = entity[key]
        const updateInputValue = updateInput[key]

        if (entityValue !== updateInputValue) {
            return { ...cleanedUpdateInput, [key]: updateInputValue }
        }

        return cleanedUpdateInput
    }, {})
}

export function mapEntitiesToIdList<EntityType extends { id: string }>(
    entities: EntityType[]
) {
    return entities.map(({ id }) => id)
}

export function getDefaultPaymentDescriptor(
    outfitter?: OutfitterFieldsFragment | null
) {
    if (!outfitter?.name) {
        return undefined
    }

    return outfitter.name.slice(0, MAX_STATEMENT_DESCRIPTOR_LENGTH)
}
