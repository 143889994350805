import React from "react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import {
    Box,
    componentStyles,
    ConditionalRenderContainer,
    HorizontalStackSmallGap,
    MediumIconButton,
    useTheme,
} from "@mallardbay/lib-react-components"
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"

import { useBreakpointValue } from "~components/shared/todo-lib-react-components/use-breakpoint-value"
import ImpersonationBannerContent from "~components/shared/todo-lib-react-components/impersonation-banner/impersonation-banner-content"
import { IconButton } from "~components/shared/todo-lib-react-components/icon-button"
import COPY from "~config/copy-constants"

// TODO: Replace in web after moving to shared lib.
// Click handlers are optional to support web, where we just show the info.

const IMPERSONATION_BG_COLOR = "rgba(76, 14, 0, 0.55)"

interface ImpersonationBannerProps {
    readonly style?: StyleProps
    readonly onSwitchImpersonationClick?: () => void
    readonly onStopImpersonatingClick?: () => void
    readonly isLoading?: boolean
}

export default function ImpersonationBanner({
    style,
    onSwitchImpersonationClick,
    onStopImpersonatingClick,
    isLoading,
}: ImpersonationBannerProps) {
    const styles = useStyles()

    const buttonConfigs = useButtonConfigs({
        onSwitchImpersonationClick,
        onStopImpersonatingClick,
    })
    const isMobile = useBreakpointValue({ base: true, md: false })
    const shouldShowMobileVersion =
        isMobile && Boolean(onSwitchImpersonationClick)

    return (
        <Box style={{ ...styles.root, ...style }}>
            <ConditionalRenderContainer shouldRender={shouldShowMobileVersion}>
                <MediumIconButton
                    style={styles.mobile}
                    ariaLabel={COPY.SWITCH_IMPERSONATION}
                    icon={<FontAwesomeIcon icon={faUsers} />}
                    onClick={onSwitchImpersonationClick}
                    color="brand"
                />
            </ConditionalRenderContainer>
            <ConditionalRenderContainer
                style={styles.desktop}
                shouldRender={!shouldShowMobileVersion}
            >
                <ImpersonationBannerContent />
                <HorizontalStackSmallGap>
                    {buttonConfigs.map((config) => (
                        <IconButton
                            key={config.ariaLabel}
                            {...config}
                            size="sm"
                            isLoading={isLoading}
                        />
                    ))}
                </HorizontalStackSmallGap>
            </ConditionalRenderContainer>
        </Box>
    )
}

function useButtonConfigs({
    onSwitchImpersonationClick,
    onStopImpersonatingClick,
}: {
    onSwitchImpersonationClick?: () => void
    onStopImpersonatingClick?: () => void
}) {
    const configs = []

    if (onSwitchImpersonationClick) {
        configs.push({
            ariaLabel: COPY.SWITCH_IMPERSONATION,
            colorScheme: "green",
            onClick: onSwitchImpersonationClick,
            icon: <FontAwesomeIcon icon={faUsers} />,
        })
    }

    if (onStopImpersonatingClick) {
        configs.push({
            ariaLabel: COPY.STOP_IMPERSONATING,
            colorScheme: "red",
            onClick: onStopImpersonatingClick,
            icon: <FontAwesomeIcon icon={faXmark} />,
        })
    }

    return configs
}

function useStyles() {
    const theme = useTheme()

    const impersonationSharedStyles = getImpersonationSharedStyles()
    const maxWidth = "500px"
    return componentStyles({
        root: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            paddingX: 2,
            paddingBottom: 6,
            maxWidth,
            margin: "0 auto",
            zIndex: theme.zIndices.overlay,
        },
        desktop: {
            display: "flex",
            alignItems: "center",
            gap: 3,
            marginX: "auto",
            rounded: "full",
            paddingRight: 4,
            paddingLeft: 6,
            paddingY: 2,

            width: "100%",
            maxWidth,
            ...impersonationSharedStyles,
        },
        mobile: {
            position: "absolute",
            left: 4,
            bottom: 4,
            width: "60px",
            height: "60px",
            fontSize: "24px",
            ...impersonationSharedStyles,
        },
    })
}

function getImpersonationSharedStyles() {
    return {
        backgroundColor: IMPERSONATION_BG_COLOR,
        backdropFilter: "blur(10px)",
        color: "white",
        _hover: {
            backgroundColor: IMPERSONATION_BG_COLOR,
        },
    }
}
