import { navigate } from "gatsby"
import { last } from "radash"

import {
    HUBSPOT_URL,
    REFERRAL_ID_SEARCH_PARAM,
    RESOURCE_TYPE,
    ROUTES,
    URL_PARAMS,
} from "~config/constants"
import { ResourceType } from "~graphql/generated/graphql"
import {
    getUrlQueryParam,
    navigateToSearchParams,
} from "~utils/helpers/helpers"

// NAVIGATION
export function navigateWithHardReload() {
    window.location.href = "/"
}

function navigateBackOrToPage(route: string) {
    void navigate(window.history.state?.back ?? route)
}

export function navigateBackOrToMyBookings() {
    void navigateBackOrToPage(ROUTES.BOOKINGS)
}

export function navigateBackOrToSendQuote() {
    void navigateBackOrToPage(ROUTES.QUOTES)
}

export function navigateToListing(listingId: string) {
    return navigate(`/manage-listings/${listingId}`)
}

export function navigateToOutfitter(outfitterId: string) {
    return navigate(`/outfitter/${outfitterId}`)
}

export function navigateToMyOutfitter(sectionId?: string) {
    const baseRoute = "/my-outfitter"
    const route = sectionId ? `${baseRoute}#${sectionId}` : baseRoute

    return navigate(route)
}

export function navigateToNewBooking(source: string) {
    const url = `/booking/new?source=${encodeURIComponent(source)}`
    return navigate(url)
}
export function navigateToBooking(bookingId?: string) {
    return navigate(getBookingUrl(bookingId))
}

export function navigateToAdditionalPayment(bookingId: string) {
    return navigate(`/booking/${bookingId}/additional-payment`)
}

export function navigateToReferralsPage(tabName: string) {
    return navigate(`/referrals/${tabName}`)
}

export function navigateToAdditionalBookingPayment({
    bookingId,
    amount,
}: {
    bookingId: string
    amount?: number
}) {
    const baseUrl = getAdditionalBookingPaymentBaseUrl({ bookingId })

    if (!amount) return navigate(baseUrl)

    return navigate(`${baseUrl}?${URL_PARAMS.AMOUNT}=${amount}`)
}

export function getAdditionalBookingPaymentBaseUrl({
    bookingId,
}: {
    bookingId: string
}) {
    return `/booking/${bookingId}/additional-booking-payment`
}

export function navigateToOnboardings() {
    return navigate(ROUTES.ONBOARDINGS)
}

export function navigateToOnboardingForm(onboardingId: string) {
    return navigate(getOnboardingFormUrl(onboardingId))
}

export function navigateToEditSubscription(outfitterId: string) {
    return navigate(`${ROUTES.EDIT_SUBSCRIPTION}/${outfitterId}`)
}

export function navigateToEditCalendar(
    resourceType: string,
    resourceId?: string
) {
    const url = getNavigateToEditCalendarUrl(resourceType, resourceId)
    return navigate(url)
}

export function navigateToEditCalendarWithoutResources() {
    return navigate(getEditCalendarUrl())
}

export function navigateToEditCalendarForListing(listingId: string) {
    const url = getNavigateToEditCalendarUrlForListing(listingId)
    void navigate(url)
}

export function navigateToOutfitterWithDelay(outfitterId: string) {
    const url = getOutfitterUrl(outfitterId)
    navigateWithDelay(url, 200)
}

export function navigateToBookingInviteForm({
    bookingInviteId,
    bookingReferralId,
}: {
    bookingInviteId?: string
    bookingReferralId?: string
} = {}) {
    const url = getBookingInviteFormUrl({ bookingInviteId, bookingReferralId })

    return navigate(url)
}

export function navigateToManageListingPage(listingId: string) {
    const url = getManageListingUrl(listingId)

    return navigate(url)
}

export function navigateToReporting() {
    const url = getReportingUrl()

    return navigate(url)
}

export function navigateToBookingInviteDetails({
    bookingInviteId,
}: {
    bookingInviteId: string
}) {
    const url = getBookingInviteDetailsUrl({ bookingInviteId })

    return navigate(url)
}

function navigateWithDelay(url: string, delay = 200) {
    setTimeout(() => {
        void navigate(url)
    }, delay)
}

// URLS
export function getOutfitterUrl(
    outfitterId: string | null | undefined
): string {
    return `/outfitter/${outfitterId}`
}

export function getGettingStartedUrlWithDomain() {
    return process.env.GATSBY_MB_ADMIN_URL
}

function getReportingUrl() {
    return `/reporting`
}

export function getWebUrl() {
    return process.env.GATSBY_MB_ROOT_URL!
}

export function getWebOutfitterUrl(outfitterSlug: string): string {
    return `${process.env.GATSBY_MB_ROOT_URL}/${outfitterSlug}`
}

export function getRequestSubscriptionChangesUrl() {
    return `${process.env.GATSBY_MB_ROOT_URL}/for-guides/subscription-requests`
}

export function getWidgetInstructionsUrl(widgetId: string) {
    return `/widget/${widgetId}/instructions`
}

export function navigateToWidgetInstructions(widgetId: string) {
    return navigate(getWidgetInstructionsUrl(widgetId))
}

export function getCalendarUrl() {
    return `/calendar`
}

export function navigateToCreateWidget(outfitterId: string) {
    return navigate(`/widget/new/${outfitterId}`)
}

function getWidgetUrl(widgetId: string) {
    return `/widget/${widgetId}`
}

export function navigateToEditWidget(widgetId: string) {
    return navigate(getWidgetUrl(widgetId))
}

export function getEditCalendarUrl() {
    return ROUTES.AVAILABILITY_CALENDAR
}

export function getBookingUrl(bookingId?: string) {
    return `/booking/${bookingId}`
}

export function getListingUrl({ listingId }: { listingId: string }) {
    return `${process.env.GATSBY_MB_ROOT_URL}/listing/${listingId}`
}

function getBookingInviteDetailsUrl({
    bookingInviteId,
}: {
    bookingInviteId: string
}) {
    return `${getSendQuoteRoute()}/${bookingInviteId}`
}

export function setResourceIdAndTypeUrlParams({
    resourceId,
    resourceType,
}: {
    resourceId: string
    resourceType: string
}) {
    return navigateToSearchParams({
        [URL_PARAMS.RESOURCE_ID]: resourceId,
        [URL_PARAMS.RESOURCE_TYPE]: resourceType,
    })
}

export function useGetResourceIdAndTypeFromUrl() {
    const resourceId = getUrlQueryParam(URL_PARAMS.RESOURCE_ID) ?? undefined
    const resourceType =
        getUrlQueryParam(URL_PARAMS.RESOURCE_TYPE) ?? RESOURCE_TYPE.LISTING

    return { resourceId, resourceType }
}

function getOnboardingFormUrl(onboardingId: string) {
    return `${ROUTES.ONBOARDINGS}/form/${onboardingId}`
}

function getBookingInviteFormUrl({
    bookingInviteId,
    bookingReferralId,
}: {
    bookingInviteId?: string
    bookingReferralId?: string
}) {
    const baseRoute = `${getSendQuoteRoute()}/form`

    const formUrl = bookingInviteId
        ? `${baseRoute}/${bookingInviteId}`
        : baseRoute
    const searchParams = bookingReferralId
        ? `?${REFERRAL_ID_SEARCH_PARAM}=${bookingReferralId}`
        : ""

    return `${formUrl}${searchParams}`
}

function getSendQuoteRoute() {
    return "/send-quote"
}

function getNavigateToEditCalendarUrl(
    resourceType: string,
    resourceId?: string
) {
    const baseUrl = "/calendar/edit"

    if (!resourceId) return baseUrl

    const lowerResourceType = resourceType.toLowerCase()

    return getEditResourceUrl({
        baseUrl,
        resourceType: lowerResourceType,
        resourceId,
    })
}

export function getEditResourceUrl({
    baseUrl,
    resourceType,
    resourceId,
}: {
    baseUrl: string
    resourceType: string
    resourceId: string
}) {
    return `${baseUrl}?${URL_PARAMS.RESOURCE_TYPE}=${resourceType}&${URL_PARAMS.RESOURCE_ID}=${resourceId}`
}

function getNavigateToEditCalendarUrlForListing(listingId: string) {
    return getNavigateToEditCalendarUrl(ResourceType.Listing, listingId)
}

export function getAccountPageUrl() {
    return `${process.env.GATSBY_MB_ROOT_URL}/account`
}

export function getPaymentRequestUrl(paymentRequestId: string) {
    return `${process.env.GATSBY_MB_ROOT_URL}/payment-request/${paymentRequestId}`
}

export function getManageListingUrl(id: string) {
    return `/manage-listings/${id}`
}

export function getWebListingUrl(id: string) {
    const webHost = process.env.GATSBY_MB_ROOT_URL

    return `${webHost}/listing/${id}`
}

export function getFullRoute() {
    const defaultRoute = window.location.pathname
    const { href, host } = window.location
    if (!href || !host) {
        return defaultRoute
    }
    // full route will be pathname plus any query string or hash suffixes (i.e. /outfitters?search=name or /outfitter/{id}/#add-ons)
    const fullRoute = last(window.location.href.split(window.location.host))

    return fullRoute ?? defaultRoute
}

export function getHubspotCompanyLink(companyId: string) {
    return `${HUBSPOT_URL}/contacts/${process.env.GATSBY_HUBSPOT_ID}/record/0-2/${companyId}`
}

export function getSelfOnboardAllSetPageUrl() {
    return "/all-set/onboard/"
}

export function navigateToHome() {
    return navigate("/")
}

export function navigateToExternalUrl(url: string) {
    window.open(url, "_blank")
}
