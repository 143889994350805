import React from "react"

import AuthProvider from "~config/context-providers/auth-provider"
import ApolloProvider from "~config/context-providers/apollo-provider"
import { UserProvider } from "~config/context-providers/user-provider"
import CourierInboxProvider from "~config/context-providers/courier-inbox-provider"
import { InAppBrowserProvider } from "~config/context-providers/in-app-browser-provider"
import ThemeProvider from "~config/context-providers/theme-provider"
import { OutfitterProvider } from "~config/context-providers/outfitter-provider"
import { PageLayoutProvider } from "~config/context-providers/page-layout-provider"
import { CalendarControlsProvider } from "~config/context-providers/calendar-controls-provider"
import SelfOnboardRedirect from "~components/self-onboard/common/self-onboard-redirect"

import "swiper/css"
import "react-datepicker/dist/react-datepicker.css"
import "~styles/uploadcare.css"
import "~styles/react-datepicker.css"

interface Props {
    readonly element: React.ReactNode
}

export default function WrapRootElement({ element }: Props) {
    return (
        <ThemeProvider>
            <PageLayoutProvider>
                <AuthProvider>
                    <ApolloProvider>
                        <UserProvider>
                            <InAppBrowserProvider>
                                <CourierInboxProvider>
                                    <OutfitterProvider>
                                        <CalendarControlsProvider>
                                            <SelfOnboardRedirect>
                                                {element}
                                            </SelfOnboardRedirect>
                                        </CalendarControlsProvider>
                                    </OutfitterProvider>
                                </CourierInboxProvider>
                            </InAppBrowserProvider>
                        </UserProvider>
                    </ApolloProvider>
                </AuthProvider>
            </PageLayoutProvider>
        </ThemeProvider>
    )
}
