import {
    addWeeks,
    endOfMonth,
    set,
    getMonth,
    startOfMonth,
    startOfDay,
    endOfDay,
    getYear,
} from "date-fns"
import React, {
    useState,
    createContext,
    useContext,
    useMemo,
    type PropsWithChildren,
} from "react"

import type { MonthBookingEventType } from "~components/shared/event-calendar/helpers/big-calendar-helpers"
import useDisclosure from "~components/shared/todo-lib-react-components/use-disclosure"
import type { BookingFieldsWithStripeUrlsAndRefundsFragment } from "~graphql/generated/graphql"

const CalendarControlsContext =
    createContext<CalendarControlsProviderType>(getDefaults())

// !IMPORANT currently, we only support month view

export function CalendarControlsProvider({ children }: PropsWithChildren) {
    const datesContext = useDatesContext()
    const {
        isOpen: isFilterModalOpen,
        onOpen: onFilterModalOpen,
        onClose: onFilterModalClose,
    } = useDisclosure()

    const context = useMemo(
        () => ({
            ...datesContext,
            isFilterModalOpen,
            onFilterModalOpen,
            onFilterModalClose,
        }),
        [datesContext, isFilterModalOpen, onFilterModalOpen, onFilterModalClose]
    )

    return (
        <CalendarControlsContext.Provider value={context}>
            {children}
        </CalendarControlsContext.Provider>
    )
}

export const useCalendarControlsContext = (): CalendarControlsProviderType => {
    return useContext(CalendarControlsContext)
}

// Context
function useDatesContext(): Omit<
    CalendarControlsProviderType,
    "isFilterModalOpen" | "onFilterModalOpen" | "onFilterModalClose"
> {
    const [selectedDates, setSelectedDates] = useState<[Date, Date]>([
        startOfDay(new Date()),
        endOfDay(new Date()),
    ])
    const [currentMonth, setCurrentMonth] = useState<Date>(
        startOfMonth(new Date())
    )
    // Rename `startAndEndOfMonthEpochMs` to `startAndEndOfPeriodEpochMs`
    const [startAndEndOfMonthEpochMs, setStartAndEndOfMonthEpochMs] = useState<
        [number, number]
    >(getStartAndEndOfPeriodForCurrentMonthDate(currentMonth))

    const setCurrentMonthWrapped = (currentMonthLocal: Date) => {
        setCurrentMonth(currentMonthLocal)
        setStartAndEndOfMonthEpochMs(
            getStartAndEndOfPeriodForCurrentMonthDate(currentMonthLocal)
        )
    }

    const incrementPeriod = (amount: number) => {
        setCurrentMonthWrapped(incrementMonth(currentMonth, amount))
    }

    const goToToday = () => {
        // It should not matter that we are leaving hours, minutes and seconds
        setCurrentMonthWrapped(new Date())
    }

    const setSelectedDatesFromBooking = (
        booking:
            | BookingFieldsWithStripeUrlsAndRefundsFragment
            | MonthBookingEventType
    ) => {
        setSelectedDates([
            startOfDay(booking.check_in_time || booking.start_date),
            endOfDay(booking.check_out_time || booking.end_date),
        ])
    }

    return {
        selectedDates,
        selectedDatesEpochMs: getSelectedDatesEpochMs(selectedDates),
        setSelectedDates,
        setSelectedDatesFromBooking,
        currentMonth,
        startAndEndOfMonthEpochMs: startAndEndOfMonthEpochMs,
        startOfPeriodEpochMs: startAndEndOfMonthEpochMs[0],
        endOfPeriodEpochMs: startAndEndOfMonthEpochMs[1],
        goToNextPeriod: () => incrementPeriod(1),
        goToPreviousPeriod: () => incrementPeriod(-1),
        goToToday,
    }
}

// Helpers

export function getStartAndEndOfPeriodForCurrentMonthDate(
    currentMonth: Date
): [number, number] {
    // The calendar show a few days before and after the actual month
    // So we add a week on each side
    const startOfMonthEpochMs = addWeekAndGetTime(
        startOfMonth(currentMonth),
        -1
    )
    const endOfMonthEpochMs = addWeekAndGetTime(endOfMonth(currentMonth), 1)

    return [startOfMonthEpochMs, endOfMonthEpochMs]
}

function addWeekAndGetTime(date: Date, increment: number) {
    return addWeeks(date, increment).getTime()
}

// This simplifies the math of adding or removing a month
// addMonths was making this more complex due to months
// having different lengths
function incrementMonth(date: Date, increment: number): Date {
    // 0: Jan, 11: Dec
    const month = getMonth(date)
    const year = getYear(date)
    let newMonth = month + increment
    let newYear = year
    if (newMonth > 11) {
        newMonth = 0
        newYear = year + 1
    }
    if (newMonth < 0) {
        newMonth = 11
        newYear = year - 1
    }

    return set(date, {
        year: newYear,
        month: newMonth,
        date: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
}

function getSelectedDatesEpochMs(
    selectedDates: [Date, Date]
): [number, number] {
    const now = new Date()
    const nowEpochMs = now.getTime()
    const timestamps = selectedDates.map((date) => date.getTime())

    return [timestamps[0] ?? nowEpochMs, timestamps[1] ?? nowEpochMs]
}

function getDefaults(): CalendarControlsProviderType {
    const now = new Date()
    const nowEpochMs = now.getTime()

    return {
        // Dates
        selectedDates: [now, now],
        selectedDatesEpochMs: [nowEpochMs, nowEpochMs],
        setSelectedDates: () => {},
        setSelectedDatesFromBooking: () => {},
        currentMonth: now,
        startAndEndOfMonthEpochMs: [nowEpochMs, nowEpochMs],
        startOfPeriodEpochMs: nowEpochMs,
        endOfPeriodEpochMs: nowEpochMs,
        goToNextPeriod: () => {},
        goToPreviousPeriod: () => {},
        goToToday: () => {},
        isFilterModalOpen: false,
        onFilterModalOpen: () => {},
        onFilterModalClose: () => {},
    }
}

export type CalendarControlsProviderType = {
    selectedDates: [Date, Date]
    selectedDatesEpochMs: [number, number]
    setSelectedDates: (dates: [Date, Date]) => void
    setSelectedDatesFromBooking: (
        booking:
            | BookingFieldsWithStripeUrlsAndRefundsFragment
            | MonthBookingEventType
    ) => void
    currentMonth: Date
    startAndEndOfMonthEpochMs: [number, number]
    startOfPeriodEpochMs: number
    endOfPeriodEpochMs: number
    goToNextPeriod: () => void
    goToPreviousPeriod: () => void
    goToToday: () => void
    isFilterModalOpen: boolean
    onFilterModalOpen: () => void
    onFilterModalClose: () => void
}
