import { useWizardContext } from "@mallardbay/lib-react-components"
import { useFormContext } from "react-hook-form"

import { SelfOnboardingStepType } from "~graphql/generated/graphql"
import { useUpdateSelfOnboardingStep } from "~utils/hooks/use-update-self-onboarding-step"

export function useSelfOnboardNavigation({
    isMainNavigation = false,
}: {
    isMainNavigation?: boolean
}) {
    const { currentNavigationStep } = useWizardContext()
    const { updateSelfOnboardingStep, isLoading: isStepUpdateLoading } =
        useUpdateSelfOnboardingStep()
    const { isLoading: isFormStepLoading, isDisabled: isFormStepDisabled } =
        useFormStepFormState()

    const isFormStep = currentNavigationStep?.hasForm

    const shouldNavigateToNextStep = isMainNavigation ? !isFormStep : true

    if (!currentNavigationStep) {
        return {}
    }

    const nextType = currentNavigationStep.nextType
    const previousType = currentNavigationStep.previousType

    const isNextStepValid = isSelfOnboardingStep(nextType)
    const isPreviousStepValid = isSelfOnboardingStep(previousType)

    const handleNextClick =
        isNextStepValid && shouldNavigateToNextStep
            ? () => {
                  void updateSelfOnboardingStep(nextType)
              }
            : undefined

    const handleBackClick = isPreviousStepValid
        ? () => {
              void updateSelfOnboardingStep(previousType)
          }
        : undefined

    return {
        handleNextClick,
        handleBackClick,
        isLoading: isStepUpdateLoading || isFormStepLoading,
        isDisabled: isFormStepDisabled,
    }
}

function useFormStepFormState() {
    const formContext = useFormContext()

    // could be falsey if we are not in a form context
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!formContext) {
        return {
            isLoading: false,
            isDisabled: false,
        }
    }

    const {
        formState: { isLoading, isSubmitting, errors },
    } = formContext

    return {
        isLoading: isLoading || isSubmitting,
        isDisabled: Object.keys(errors).length > 0,
    }
}

function isSelfOnboardingStep(step?: string): step is SelfOnboardingStepType {
    if (!step) {
        return false
    }

    return Object.values<string>(SelfOnboardingStepType).includes(step)
}
