import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Box,
    Flex,
    Text,
    Center,
    Button,
    Spacer,
    Divider,
} from "@chakra-ui/react"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import TextLogo from "~components/shared/header/logo"
import IconLogo from "~components/shared/header/icon-logo"
import SidebarContent from "~components/shared/sidebar/sidebar-content"
import SelectedOutfitterPermissionsProvider from "~components/shared/permissions/selected-outfitter-permissions-provider"
import packageJson from "~package"
import { usePageLayout } from "~config/context-providers/page-layout-provider"
import { TEST_IDS } from "~config/constants"

import { SIDEBAR_WIDTHS } from "./use-sidebar-inline-width"

export default function SidebarEmbedded() {
    const { isSidebarOpen, setIsSidebarOpen } = usePageLayout()

    return (
        <Flex
            data-testid={TEST_IDS.SIDEBAR_EMBEDDED}
            height="100%"
            width={isSidebarOpen ? SIDEBAR_WIDTHS.OPEN : SIDEBAR_WIDTHS.CLOSED}
            direction="column"
            transition="width .3s, background-color .2s, display .3s"
            position="relative"
        >
            <Center minH="64px">
                {isSidebarOpen ? <TextLogo /> : <IconLogo boxSize="52px" />}
            </Center>
            <Divider />
            <Box overflow="auto">
                <SelectedOutfitterPermissionsProvider>
                    <SidebarContent isCollapsed={!isSidebarOpen} />
                </SelectedOutfitterPermissionsProvider>
            </Box>
            <Spacer />
            <Box display={["none", "initial"]}>
                <Button
                    width="full"
                    borderRadius={0}
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                    <Box
                        transform={isSidebarOpen ? "" : "rotate(-180deg)"}
                        transition="transform .3s"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Box>
                </Button>
                <Divider />

                <Text textAlign="center" color="gray.400" fontSize="xs">
                    v{packageJson.version}
                </Text>
            </Box>
        </Flex>
    )
}
