import React from "react"
import { createPortal } from "react-dom"
import {
    Box,
    componentStyles,
    IconButton,
    useColors,
} from "@mallardbay/lib-react-components"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { HUBSPOT_INLINE_EMBED_ELEMENT_ID, TEST_IDS } from "~config/constants"
import { closeChatWidget } from "~utils/helpers/hubspot-helpers"

export default function HubspotChat() {
    const styles = useStyles()

    return (
        <>
            {createPortal(
                <Box
                    style={styles.container}
                    id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
                >
                    <IconButton
                        aria-label="close chat"
                        icon={<FontAwesomeIcon icon={faXmark} fontSize={24} />}
                        style={styles.iconButton}
                        onClick={closeChatWidget}
                        testId={TEST_IDS.HUBSPOT_CHAT_CLOSE_BUTTON}
                    />
                </Box>,
                // eslint-disable-next-line ssr-friendly/no-dom-globals-in-react-fc
                document.body
            )}
        </>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        container: {
            position: "fixed",
            width: { lg: "420px", base: "100%" },
            height: { lg: "674px", base: "100%" },
            bottom: { lg: 50, base: 0 },
            right: { lg: 50, base: 0 },
            zIndex: 9999,
            visibility: "hidden",
        },
        iconButton: {
            position: "absolute",
            bottom: { lg: -5, base: "auto" },
            right: { lg: -5, base: "55px" },
            top: { lg: "auto", base: "11px" },
            size: "lg",
            colorScheme: "orange",
            backgroundColor: colors.warningOrange,
            rounded: "full",
        },
    })
}
