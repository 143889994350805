import {
    isPossiblePhoneNumber,
    parsePhoneNumberFromString,
} from "libphonenumber-js"

import { CURRENCY_LOCALE } from "~config/constants"
import COPY from "~config/copy-constants"
// Function that takes a phone number in the format +19999999999 and returns it as +1 (999) 999-9999

// Will return the original string if it is not a valid phone number
export function formatPhoneNumber(phoneNumber: string | null | undefined) {
    if (!phoneNumber) return ""

    const parsedPhone = parsePhoneNumberFromString(phoneNumber)

    return parsedPhone?.formatInternational() ?? phoneNumber
}

export function formatPrice(
    price: number | null | undefined,
    hideZeroCents = false
): string {
    if (typeof price !== "number") return "N/A"

    const negativeNotation = price < 0 ? "-" : ""
    const valueString = centsToDollars(Math.abs(price)).toLocaleString(
        undefined,
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
    )

    const formattedPrice = `${negativeNotation}$${valueString}`

    return hideZeroCents ? formattedPrice.replace(".00", "") : formattedPrice
}

function toCompactNumber(number: number): string {
    return Intl.NumberFormat(CURRENCY_LOCALE.US, {
        notation: "compact",
        maximumFractionDigits: 1,
    }).format(number)
}

export function toCompactPrice(number: number): string {
    return `$${toCompactNumber(number)}`
}

export function formatPercentage(percent: number | null | undefined) {
    if (typeof percent !== "number") return COPY.NA

    return `${percent}%`
}

export function formatBooleanToYesNo(value: boolean | null | undefined) {
    return value ? COPY.YES : COPY.NO
}

export function centsToDollars(cents: number): number {
    return cents / 100
}

export function dollarsToCents(dollars: number | string): number {
    const cleanedDollars = cleanNumStr(dollars.toString())
    const cents = cleanedDollars * 100
    return Math.round(cents)
}

export function cleanNumStr(numStr: string): number {
    const num = Number(numStr.replace(/[^0-9.-]+/g, ""))
    if (isNaN(num)) return 0
    return num
}

export function getCleanEnum(dirty: string | undefined): string {
    return dirty
        ? dirty
              .replace(/_/g, " ")
              .split(" ")
              .map((str) => str.charAt(0) + str.slice(1).toLowerCase())
              .join(" ")
        : ""
}

export function makeStringFromArray({
    strings,
    delimitter = " ",
}: {
    strings: (string | null | undefined)[]
    delimitter?: string
}) {
    return strings
        .filter((stringValue) => Boolean(stringValue))
        .join(delimitter)
}

export function getFullName(
    user?: {
        first_name?: string | null
        last_name?: string | null
    } | null
): string {
    if (user?.first_name && user.last_name) {
        return `${user.first_name} ${user.last_name}`
    }

    return user?.first_name ?? user?.last_name ?? "N/A"
}

export function validatePhoneNumber({
    phone,
    isEmptyValid = true,
}: {
    phone: string | null | undefined
    isEmptyValid?: boolean
}): boolean {
    if (!phone) return isEmptyValid

    const hasCountryCode = phone.startsWith("+")

    return hasCountryCode && isPossiblePhoneNumber(phone)
}

export function startsWithAny(value: string, values: string[]) {
    return values.some((valueToCheck) => value.startsWith(valueToCheck))
}
