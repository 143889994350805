export enum EventName {
    KNOWLEDGE_BASED_ACTION_BANNER_CLICKED = "knowledge_base_action_banner_clicked",
    BOOKING_ACCEPTED = "booking_accepted",
    EDIT_LISTING = "edit_listing",
    EDIT_OUTFITTER = "edit_outfitter",
    CREATE_BOOKING = "create_booking",
    CREATE_ADDITIONAL_PAYMENT = "create_additional_payment",
    CREATE_ADDITIONAL_BOOKING_PAYMENT = "create_additional_booking_payment",
    DECLINED_BOOKING = "declined_booking",
    OFFLINE_PAYMENT = "offline_payment",
    CREATE_QUICK_PAY = "create_quick_pay",
    CREATE_COUPON = "create_coupon",
    ADDED_TEAM_MEMBER = "added_team_member",
    CREATE_REFERRAL = "create_referral",
    ACCEPT_BOOKING_REFERRAL = "accept_booking_referral",
    ADDITIONAL_PAYMENT_REQUEST = "additional_payment_request",
    AVAILABILITY = "availability",
    EXPORT_PAYMENTS_CSV = "export_payments_csv",
    EXPORT_REMAINING_PAYMENTS_CSV = "export_remaining_payments_csv",
    CREATE_BOOKING_INVITE = "create_booking_invite",
    DECLINE_BOOKING_REFERRAL = "decline_booking_referral",
    CREATE_RESOURCE = "create_resource",
}
