// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { MenuItem, Text, Link } from "@chakra-ui/react"
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    componentStyles,
    HorizontalStackSpaceBetween,
} from "@mallardbay/lib-react-components"
import React from "react"

interface Props {
    readonly label: string
    readonly icon: IconDefinition
    readonly href?: string
    readonly isExternal?: boolean
    readonly onClick?: () => void
}

export default function IconMenuItem({
    label,
    icon,
    href,
    isExternal,
    onClick,
}: Props) {
    const styles = useStyles()

    const menuItemProps = href ? { as: Link, href, isExternal } : {}

    return (
        <MenuItem {...menuItemProps} onClick={onClick}>
            <HorizontalStackSpaceBetween style={styles.stack}>
                <Text>{label}</Text>
                <FontAwesomeIcon icon={icon} />
            </HorizontalStackSpaceBetween>
        </MenuItem>
    )
}

function useStyles() {
    return componentStyles({
        stack: {
            flex: 1,
            gap: 8,
        },
    })
}
