import type { PropsWithChildren } from "react"
import React, { createContext, useContext, useMemo } from "react"
import { useLocalStorage } from "usehooks-ts"

import { LOCAL_STORAGE_KEYS } from "~config/constants"
import { useIsMobile } from "~utils/hooks/use-is-mobile"

interface ContextType {
    isSidebarOpen: boolean
    setIsSidebarOpen: (isOpen: boolean) => void
    openSidebar: () => void
    closeSidebar: () => void
    closeSidebarIfMobile: () => void
    isMobile: boolean
}

// Exported for tests
export const Context = createContext<ContextType>({
    isSidebarOpen: false,
    setIsSidebarOpen: () => {},
    openSidebar: () => {},
    closeSidebar: () => {},
    closeSidebarIfMobile: () => {},
    isMobile: false,
})

export function PageLayoutProvider({ children }: Readonly<PropsWithChildren>) {
    const isMobile = useIsMobile()

    const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage<boolean>(
        LOCAL_STORAGE_KEYS.MB_IS_SIDEBAR_OPEN,
        false
    )

    const openSidebar = () => {
        // Don't open if it is already
        if (isSidebarOpen) return
        setIsSidebarOpen(true)
    }
    const closeSidebar = () => {
        // Don't close if it is already
        if (!isSidebarOpen) return
        setIsSidebarOpen(false)
    }
    const closeSidebarIfMobile = () => {
        if (isMobile) closeSidebar()
    }

    const value = useMemo(() => {
        return {
            isSidebarOpen,
            setIsSidebarOpen,
            openSidebar,
            closeSidebar,
            closeSidebarIfMobile,
            isMobile,
        }
    }, [isSidebarOpen, isMobile])

    return <Context.Provider value={value}>{children}</Context.Provider>
}

export function usePageLayout(): ContextType {
    return useContext(Context)
}
