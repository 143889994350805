// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { useBreakpointValue } from "@chakra-ui/react"

export function useIsTablet() {
    return (
        useBreakpointValue({ base: false, sm: false, md: true, lg: false }) ??
        false
    )
}
