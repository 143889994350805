import React from "react"
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear"

import { useStripeAccountInfo } from "~utils/hooks/use-stripe-account-info"
import StripeAccountButton from "~components/shared/header/stripe-account-button"
import IconMenuItem from "~components/shared/icon-menu-item"
import { PROFILE_MENU_LABELS } from "~config/constants"
import { getAccountPageUrl } from "~utils/helpers/navigation-helpers"

export default function AccountSettingsSection() {
    const { stripeAccountInfo } = useStripeAccountInfo()

    const showStripeAccount = stripeAccountInfo?.is_onboarded

    return (
        <>
            {showStripeAccount && <StripeAccountButton />}
            <IconMenuItem
                href={getAccountPageUrl()}
                isExternal
                label={PROFILE_MENU_LABELS.ACCOUNT_SETTINGS}
                icon={faGear}
            />
        </>
    )
}
