import {
    OutfitterStatus,
    useOutfittersByOwnerQuery,
} from "~graphql/generated/graphql"
import { useUser } from "~config/context-providers/user-provider"

export default function useMyOutfitters() {
    const {
        user,
        isLoading: isUserLoading,
        isAdminNotImpersonating,
    } = useUser()
    const {
        data,
        error,
        loading: isOutfittersLoading,
    } = useOutfittersByOwnerQuery({
        variables: { id: user?.id ?? "" },
        skip: !user?.id,
    })

    const outfitters = data?.outfittersByOwner ?? []
    const approvedOutfitters = filterApprovedOutfitters(outfitters)
    const defaultOutfitter = isAdminNotImpersonating ? null : outfitters[0]
    const isLoading = isUserLoading || isOutfittersLoading
    const hasOutfitters = Boolean(outfitters.length)
    const hasSelfOnboardedOutfitters = getHasSelfOnboardedOutfitters(outfitters)

    return {
        outfitters,
        defaultOutfitter,
        hasOutfitters,
        hasApprovedOutfitters: approvedOutfitters.length > 0,
        isLoading,
        error,
        hasSelfOnboardedOutfitters,
    }
}

function filterApprovedOutfitters<T extends { status: string }>(
    outfitters: T[]
): T[] {
    return outfitters.filter(
        (outfitter) => outfitter.status === OutfitterStatus.Approved
    )
}

function getHasSelfOnboardedOutfitters<
    T extends { is_self_onboarded?: boolean | null },
>(outfitters: T[]) {
    return outfitters.some((outfitter) => !!outfitter.is_self_onboarded)
}
