import type { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome"
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons/faCalendarWeek"
import { faList } from "@fortawesome/free-solid-svg-icons/faList"
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons/faSquareCheck"
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser"
import { faInbox } from "@fortawesome/free-solid-svg-icons/faInbox"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane"
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar"
import { faPercent } from "@fortawesome/free-solid-svg-icons/faPercent"
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign"
import { faFastForward } from "@fortawesome/free-solid-svg-icons/faFastForward"
import { faFeather } from "@fortawesome/free-solid-svg-icons/faFeather"
import { faPenToSquare as faPenToSquareOutline } from "@fortawesome/free-regular-svg-icons/faPenToSquare"
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers"
import { faCoffee } from "@fortawesome/free-solid-svg-icons/faCoffee"
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus"
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook"

import { FeatureKey } from "~graphql/generated/graphql"
import {
    PAGES,
    ADMIN_PAGES,
    ROUTES,
    RESOURCES_URL,
    FEATURE_KNOWLEDGE_CTA_URL_PATHS,
} from "~config/constants"
import { useFeature } from "~utils/hooks/use-feature-access"
import { usePermissions } from "~components/shared/permissions/permissions-provider"
import { useUser } from "~config/context-providers/user-provider"
import COPY from "~config/copy-constants"

export function useSidebarItemGroups() {
    const { shouldShowActions } = usePermissions()
    const mainItems = useMainItems()
    const adminItems = useAdminItems()
    const filteredMainItems = mainItems.filter(
        (item) => !item.requiresActionPermission || shouldShowActions
    )

    return [filteredMainItems, adminItems]
}

function useMainItems(): SidebarItem[] {
    const { shouldShowActions } = usePermissions()
    const { isAdminNotImpersonating } = useUser()

    const { isEnabled: isMessagingEnabled } = useFeature(FeatureKey.Messaging)
    const { isEnabled: isBookingInviteEnabled } = useFeature(
        FeatureKey.BookingInvites
    )
    const { isEnabled: isCouponsEnabled } = useFeature(FeatureKey.Coupons)
    const { isEnabled: isQuickPayEnabled } = useFeature(FeatureKey.QuickPay)
    const { isEnabled: isBookingsCalendarEnabled } = useFeature(
        FeatureKey.ViewBookingsInCalendar
    )

    const areAllReportsPaywalled = useAreAllReportsPaywalled()

    const calendarRoute = isBookingsCalendarEnabled
        ? ROUTES.CALENDAR
        : ROUTES.AVAILABILITY_CALENDAR

    return [
        {
            name: PAGES.HOME.name,
            label: PAGES.HOME.label,
            link: ROUTES.HOME,
            icon: faHome,
        },
        {
            name: PAGES.CALENDAR.name,
            label: PAGES.CALENDAR.label,
            link: calendarRoute,
            icon: faCalendarWeek,
        },
        {
            name: PAGES.BOOKINGS.name,
            label: PAGES.BOOKINGS.label,
            link: ROUTES.BOOKINGS,
            icon: faSquareCheck,
        },
        ...(isAdminNotImpersonating
            ? []
            : [
                  {
                      name: PAGES.MY_OUTFITTER.name,
                      label: PAGES.MY_OUTFITTER.label,
                      link: ROUTES.MY_OUTFITTER,
                      icon: faUser,
                  },
              ]),
        {
            name: PAGES.LISTINGS.name,
            label: PAGES.LISTINGS.label,
            link: ROUTES.MANAGE_LISTINGS,
            icon: faList,
        },
        ...(isAdminNotImpersonating
            ? []
            : [
                  {
                      name: PAGES.INBOX.name,
                      label: PAGES.INBOX.label,
                      link: ROUTES.INBOX,
                      icon: faInbox,
                      requiresActionPermission: true,
                      isPaywalled: !isMessagingEnabled,
                  },
              ]),
        {
            name: PAGES.QUOTES.name,
            label: PAGES.QUOTES.label,
            link: ROUTES.QUOTES,
            icon: faPaperPlane,
            isPaywalled: !isBookingInviteEnabled,
        },
        {
            name: PAGES.REPORTS.name,
            label: PAGES.REPORTS.label,
            link: ROUTES.REPORTING,
            icon: faChartBar,
            requiresActionPermission: true,
            isPaywalled: areAllReportsPaywalled,
        },
        ...(shouldShowActions
            ? [
                  {
                      name: PAGES.REFERRALS.name,
                      label: PAGES.REFERRALS.label,
                      link: ROUTES.REFERRALS,
                      icon: faFastForward,
                  },
              ]
            : []),
        {
            name: PAGES.COUPONS.name,
            label: PAGES.COUPONS.label,
            link: ROUTES.COUPONS,
            icon: faPercent,
            isPaywalled: !isCouponsEnabled,
        },
        ...(shouldShowActions
            ? [
                  {
                      name: PAGES.QUICK_PAY.name,
                      label: PAGES.QUICK_PAY.label,
                      link: ROUTES.QUICK_PAY,
                      icon: faDollarSign,
                      isPaywalled: !isQuickPayEnabled,
                  },
              ]
            : []),
        ...(isAdminNotImpersonating
            ? []
            : [
                  {
                      name: COPY.KNOWLEDGE_BASE,
                      label: COPY.KNOWLEDGE_BASE,
                      link: getKnowledgeLink(),
                      icon: faBook,
                      isExternalLink: true,
                  },
              ]),
    ]
}

function useAdminItems(): SidebarItem[] {
    const { isAdminNotImpersonating } = useUser()

    const shouldShowAdminItems = isAdminNotImpersonating

    if (!shouldShowAdminItems) return []

    return [
        {
            name: ADMIN_PAGES.ONBOARDINGS.name,
            label: ADMIN_PAGES.ONBOARDINGS.label,
            link: ROUTES.ONBOARDINGS,
            icon: faUserPlus,
        },
        {
            name: ADMIN_PAGES.OUTFITTERS.name,
            label: ADMIN_PAGES.OUTFITTERS.label,
            link: ROUTES.OUTFITTERS,
            icon: faUsers,
        },
        {
            name: ADMIN_PAGES.AMENITIES.name,
            label: ADMIN_PAGES.AMENITIES.label,
            link: ROUTES.AMENITIES,
            icon: faCoffee,
        },
        {
            name: ADMIN_PAGES.SPECIES.name,
            label: ADMIN_PAGES.SPECIES.label,
            link: ROUTES.SPECIES,
            icon: faFeather,
        },
        {
            name: ADMIN_PAGES.LICENSES.name,
            label: ADMIN_PAGES.LICENSES.label,
            link: ROUTES.LICENSES,
            icon: faPenToSquareOutline,
        },
    ]
}

function useAreAllReportsPaywalled() {
    const { isEnabled: isBasicEnabled } = useFeature(FeatureKey.ReportingBasic)
    const { isEnabled: isBusinessPerformanceEnabled } = useFeature(
        FeatureKey.ReportingBusinessPerformance
    )
    const { isEnabled: isPaymentsEnabled } = useFeature(
        FeatureKey.ReportingPayments
    )
    const { isEnabled: isPayoutsEnabled } = useFeature(
        FeatureKey.ReportingPayouts
    )
    const { isEnabled: isUnpaidBalancesEnabled } = useFeature(
        FeatureKey.ReportingUnpaidBalances
    )

    return (
        !isBasicEnabled &&
        !isBusinessPerformanceEnabled &&
        !isPaymentsEnabled &&
        !isPayoutsEnabled &&
        !isUnpaidBalancesEnabled
    )
}

type SidebarItem = {
    name: string
    label: string
    link: string
    requiresActionPermission?: boolean
    icon: IconDefinition
    isPaywalled?: boolean
    isExternalLink?: boolean
}

function getKnowledgeLink() {
    return `${RESOURCES_URL}${FEATURE_KNOWLEDGE_CTA_URL_PATHS.OUTFITTER_SUCCESS}`
}
