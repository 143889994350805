import React, { useMemo } from "react"
import {
    componentStyles,
    HorizontalStackSmallGap,
    Menu,
    Text,
} from "@mallardbay/lib-react-components"
import { faEarthAsia } from "@fortawesome/free-solid-svg-icons/faEarthAsia"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useOutfitterSelectOptions } from "~components/shared/outfitter-select/outfitter-select-helpers"
import type { OutfitterSelectOptionType } from "~components/shared/outfitter-select/outfitter-select-helpers"
import COPY from "~config/copy-constants"
import Avatar from "~components/shared/todo-lib-react-components/avatar"
import { TEST_IDS } from "~config/constants"

export interface OutfitterSelectProps {
    readonly shouldDefaultToFirstOutfitter?: boolean
    readonly shouldAllowOutfittersAdminSearch?: boolean
    readonly shouldAllowAllOutfittersOption?: boolean
    readonly selectedOutfitter: OutfitterSelectOptionType | null
    readonly onChange: (outfitter: OutfitterSelectOptionType | null) => void
    readonly isDisabled?: boolean
}

export default function OutfitterSelect({
    shouldDefaultToFirstOutfitter = true,
    shouldAllowOutfittersAdminSearch = false,
    shouldAllowAllOutfittersOption = false,
    selectedOutfitter,
    onChange,
    isDisabled,
}: OutfitterSelectProps) {
    const styles = useStyles()

    const {
        searchText,
        setSearchText,
        outfitterOptions,
        isLoadingMyOutfitters,
        isLoadingSearchOutfitters,
        shouldSearchForOutfitters,
    } = useOutfitterSelectOptions({
        selectedOutfitter,
        shouldDefaultToFirstOutfitter,
        shouldAllowOutfittersAdminSearch,
        onChange,
    })

    const menuItems = useMemo(
        () =>
            getMenuItems({
                shouldAllowAllOutfittersOption,
                onChange,
                outfitterOptions,
                iconStyles: styles.icon,
            }),
        [shouldAllowAllOutfittersOption, onChange, outfitterOptions]
    )

    if (
        !outfitterOptions.length &&
        !isLoadingMyOutfitters &&
        !isLoadingSearchOutfitters &&
        !shouldSearchForOutfitters // Don't hide the selector, since searching can return no results for outfitterOptions
    ) {
        return null
    }

    const placeholder = getPlaceholder({
        shouldAllowAllOutfittersOption,
        isLoading: isLoadingMyOutfitters,
    })

    return (
        <Menu
            menuItems={menuItems}
            isDisabled={isDisabled}
            menuSearchProps={{
                isSearching: isLoadingSearchOutfitters,
                searchText,
                onSearchTextChange: setSearchText,
                placeholder: COPY.SEARCH_ALL_OUTFITTERS,
            }}
            isLoading={isLoadingMyOutfitters}
            style={styles.menuButton}
            rightIcon={faChevronDown}
            testId={TEST_IDS.OUTFITTER_SELECT}
        >
            {selectedOutfitter && (
                <HorizontalStackSmallGap style={styles.hStack}>
                    <Avatar
                        title={selectedOutfitter.name || ""}
                        src={selectedOutfitter.avatar?.url}
                        style={styles.icon}
                    />
                    <Text>{selectedOutfitter.name}</Text>
                </HorizontalStackSmallGap>
            )}
            {!selectedOutfitter && (
                <Text style={styles.placeholder}>{placeholder}</Text>
            )}
        </Menu>
    )
}

function getMenuItems({
    shouldAllowAllOutfittersOption,
    onChange,
    outfitterOptions,
    iconStyles,
}: {
    shouldAllowAllOutfittersOption?: boolean
    onChange: (outfitter: OutfitterSelectOptionType | null) => void
    outfitterOptions: OutfitterSelectOptionType[]
    iconStyles: {
        width: string
        height: string
    }
}) {
    const allOutfittersMenuItem = {
        label: COPY.ALL_OUTFITTERS,
        onClick: () => onChange(null),
        icon: <FontAwesomeIcon icon={faEarthAsia} style={iconStyles} />,
    }

    const menuItems = outfitterOptions.map((outfitter) => ({
        label: outfitter.name,
        onClick: () => onChange(outfitter),
        icon: <Avatar src={outfitter.avatar?.url} style={iconStyles} />,
    }))

    if (!shouldAllowAllOutfittersOption) {
        return menuItems
    }

    return [allOutfittersMenuItem, ...menuItems]
}

function useStyles() {
    return componentStyles({
        menuButton: {
            width: "100%",
            overflow: "hidden",
        },
        icon: {
            width: "22px",
            height: "22px",
        },
        hStack: {
            overflow: "hidden",
        },
        placeholder: {
            overflow: "hidden",
        },
    })
}

function getPlaceholder({
    shouldAllowAllOutfittersOption,
    isLoading,
}: {
    shouldAllowAllOutfittersOption?: boolean
    isLoading?: boolean
}) {
    if (isLoading) return COPY.LOADING

    return shouldAllowAllOutfittersOption
        ? COPY.ALL_OUTFITTERS
        : COPY.SELECT_OUTFITTER
}
