import { useOutfitterContext } from "~config/context-providers/outfitter-provider"
import type { SelfOnboardingStepType } from "~graphql/generated/graphql"
import { useUpdateSelfOnboardingStepMutation } from "~graphql/generated/graphql"

export function useUpdateSelfOnboardingStep() {
    const { outfitter } = useOutfitterContext()

    const [updateStepMutation, { loading: isLoading }] =
        useUpdateSelfOnboardingStepMutation()

    const updateSelfOnboardingStep = async (step: SelfOnboardingStepType) => {
        // outfitter may not be loaded yet since we are fetching it from apollo cache
        if (!outfitter) {
            return
        }

        await updateStepMutation({ variables: { id: outfitter.id, step } })
    }

    return { updateSelfOnboardingStep, isLoading }
}
