import { navigate } from "gatsby"
import { useEffect } from "react"

interface RedirectProps {
    readonly to: string
}

// simple redirect using gatsby navigate to avoid SSR issues
export default function Redirect({ to }: RedirectProps) {
    useEffect(() => {
        void navigate(to)
    }, [to])

    return null
}
