import { usePageLayout } from "~config/context-providers/page-layout-provider"

export const SIDEBAR_WIDTHS = {
    OPEN: "250px",
    CLOSED: "72px",
    HIDDEN: "0px",
}

export default function useSidebarInlineWidth() {
    const { isSidebarOpen, isMobile } = usePageLayout()
    if (isMobile) return SIDEBAR_WIDTHS.HIDDEN

    return isSidebarOpen ? SIDEBAR_WIDTHS.OPEN : SIDEBAR_WIDTHS.CLOSED
}
