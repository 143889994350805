import React from "react"

import PageLayout from "~components/shared/page-layout/page-layout"
import GlobalAuthentication from "~components/shared/global-authentication"

interface Props {
    readonly element: React.ReactNode
    readonly props: { location: Location }
}

export default function WrapPageElement({ element, props }: Props) {
    if (props.location.pathname.includes("self-onboard")) {
        return <GlobalAuthentication>{element}</GlobalAuthentication>
    }

    return (
        <GlobalAuthentication>
            <PageLayout>{element}</PageLayout>
        </GlobalAuthentication>
    )
}
