// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { MenuList, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers"
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate"
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons/faArrowRightFromBracket"

import { useAuth } from "~utils/hooks/use-auth"
import ViewingAsDisplay from "~components/shared/profile-menu/viewing-as-display"
import ProfileMenuDivider from "~components/shared/profile-menu/profile-menu-divider"
import IconMenuItem from "~components/shared/icon-menu-item"
import ConditionalBox from "~components/shared/containers/conditional-box"
import { PROFILE_MENU_LABELS } from "~config/constants"
import ImpersonationModal from "~components/shared/auth/impersonation-modal"
import { useUser } from "~config/context-providers/user-provider"
import { useStopImpersonationMutation } from "~graphql/generated/graphql"
import AccountSettingsSection from "~components/shared/profile-menu/account-settings-section"
import HubspotMenuItem from "~components/shared/profile-menu/hubspot-menu-item"
import { getRequestSubscriptionChangesUrl } from "~utils/helpers/navigation-helpers"
import HubspotChat from "~components/shared/profile-menu/hubspot-chat"

interface ProfileMenuProps {
    showAccountSettings?: boolean
}

export default function ProfileMenu({
    showAccountSettings,
}: Readonly<ProfileMenuProps>) {
    const { isAdmin, isImpersonating } = useUser()

    const {
        isOpen: isImpersonationOpen,
        onOpen: onImpersonationOpen,
        onClose: onImpersonationClose,
    } = useDisclosure()
    const handleLogout = useHandleLogout()

    return (
        <>
            <MenuList shadow="xl" rounded="xl" pos="relative">
                <ViewingAsDisplay />
                <ConditionalBox showIf={isAdmin || isImpersonating}>
                    <ProfileMenuDivider />
                    <IconMenuItem
                        onClick={onImpersonationOpen}
                        label={PROFILE_MENU_LABELS.VIEW_AS_LABEL}
                        icon={faUsers}
                    />
                </ConditionalBox>
                <ProfileMenuDivider />
                <ProfileMenuDivider />
                <HubspotMenuItem />
                {showAccountSettings && <AccountSettingsSection />}
                <IconMenuItem
                    href={getRequestSubscriptionChangesUrl()}
                    label={PROFILE_MENU_LABELS.REQUEST_SUBSCRIPTION_CHANGES}
                    icon={faRotate}
                />
                <IconMenuItem
                    onClick={handleLogout}
                    label={PROFILE_MENU_LABELS.LOG_OUT_LABEL}
                    icon={faArrowRightFromBracket}
                />
            </MenuList>
            <ImpersonationModal
                isOpen={isImpersonationOpen}
                onClose={onImpersonationClose}
            />
            <HubspotChat />
        </>
    )
}

function useHandleLogout() {
    const [stopImpersonation] = useStopImpersonationMutation()
    const { logout } = useAuth()

    return () => {
        void stopImpersonation()
        void logout()
    }
}
