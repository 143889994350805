import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { faMessage as faMessageOutline } from "@fortawesome/free-regular-svg-icons/faMessage"

import { openChatWidget } from "~utils/helpers/hubspot-helpers"
import { PROFILE_MENU_LABELS, TEST_IDS } from "~config/constants"
import IconMenuItem from "~components/shared/icon-menu-item"

export default function HubspotMenuItem() {
    return (
        <IconMenuItem
            data-testid={TEST_IDS.HUBSPOT_MENU_ITEM}
            icon={faMessageOutline}
            label={PROFILE_MENU_LABELS.CHAT_WITH_SUPPORT}
            onClick={openChatWidget}
        />
    )
}
