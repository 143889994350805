import { useMatch } from "@reach/router"

import {
    getCalendarUrl,
    getEditCalendarUrl,
} from "~utils/helpers/navigation-helpers"

export function useIsCalendarEditPage() {
    return useMatch(getEditCalendarUrl())
}

function useIsCalendarPage() {
    return useMatch(getCalendarUrl())
}

export function useIsEitherCalendarPage() {
    const isCalendarPage = useIsCalendarPage()
    const isCalendarEditPage = useIsCalendarEditPage()
    return isCalendarPage || isCalendarEditPage
}
