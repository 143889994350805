import {
    Box,
    componentStyles,
    useTheme,
} from "@mallardbay/lib-react-components"
import React from "react"

import PaywallIcon from "~components/shared/paywall-icon"

export default function SidebarPaywallIndicator() {
    const styles = useStyleProps()

    return (
        <Box style={styles.paywalled}>
            <PaywallIcon />
        </Box>
    )
}

function useStyleProps() {
    const { colors } = useTheme()

    return componentStyles({
        paywalled: {
            backgroundColor: colors.bgColorSecondary,
            color: colors.borderColorDark,
            rounded: "lg",
            padding: "4px 10px",
            marginX: "12px",
        },
    })
}
