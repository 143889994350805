import { EventName } from "~utils/event-tracking/event-tracking-constants"
import { trackEvent } from "~utils/analytics-hooks/use-analytics"
import type { ResourceType } from "~graphql/generated/graphql"

// Specific events
export function useTrackKnowledgeBaseActionBannerClicked() {
    return ({ path, url }: { path: string; url: string }) =>
        trackEvent(EventName.KNOWLEDGE_BASED_ACTION_BANNER_CLICKED, {
            path,
            url,
        })
}

export function useTrackBookingAccepted() {
    return ({ bookingId }: { bookingId: string }) => {
        trackEvent(EventName.BOOKING_ACCEPTED, { booking_id: bookingId })
    }
}

export function useTrackEditListing() {
    return ({
        listingId,
        updatedSection,
    }: {
        listingId: string
        updatedSection: string
    }) => {
        trackEvent(EventName.EDIT_LISTING, {
            listing_id: listingId,
            updated_property: updatedSection,
        })
    }
}

export function useTrackEditOutfitter() {
    return ({
        outfitterId,
        updatedSection,
    }: {
        outfitterId: string
        updatedSection: string
    }) => {
        trackEvent(EventName.EDIT_OUTFITTER, {
            outfitter_id: outfitterId,
            updated_section: updatedSection,
        })
    }
}

export function useTrackCreateBooking() {
    return ({
        bookingId,
        outfitterId,
        source,
    }: {
        bookingId: string
        outfitterId: string
        source: string | null
    }) => {
        trackEvent(EventName.CREATE_BOOKING, {
            booking_id: bookingId,
            outfitter_id: outfitterId,
            source,
        })
    }
}

export function useTrackCreateAdditionalBookingPayment() {
    return ({
        bookingId,
        paymentId,
    }: {
        bookingId: string
        paymentId: string
    }) => {
        trackEvent(EventName.CREATE_ADDITIONAL_BOOKING_PAYMENT, {
            booking_id: bookingId,
            payment_id: paymentId,
        })
    }
}

export function useTrackDeclinedBooking() {
    return ({
        bookingId,
        denialReason,
    }: {
        bookingId: string
        denialReason?: string
    }) => {
        trackEvent(EventName.DECLINED_BOOKING, {
            booking_id: bookingId,
            denial_reason: denialReason,
        })
    }
}

export function useTrackLoggedOfflinePayment() {
    return ({ paymentId }: { paymentId: string }) => {
        trackEvent(EventName.OFFLINE_PAYMENT, {
            payment_id: paymentId,
        })
    }
}

export function useTrackCreateQuickPay() {
    return ({
        paymentId,
        outfitterId,
    }: {
        paymentId: string
        outfitterId: string
    }) => {
        trackEvent(EventName.CREATE_QUICK_PAY, {
            payment_id: paymentId,
            outfitter_id: outfitterId,
        })
    }
}

export function useTrackCreateCoupon() {
    return ({
        couponId,
        couponCode,
    }: {
        couponId: string
        couponCode: string
    }) => {
        trackEvent(EventName.CREATE_COUPON, {
            coupon_id: couponId,
            coupon_code: couponCode,
        })
    }
}

export function useTrackCreateReferral() {
    return ({ outfitterId }: { outfitterId?: string }) => {
        trackEvent(EventName.CREATE_REFERRAL, {
            outfitter_id: outfitterId,
        })
    }
}

export function useTrackAcceptBookingReferral() {
    return ({
        referralId,
        bookingId,
    }: {
        referralId: string
        bookingId: string
    }) => {
        trackEvent(EventName.ACCEPT_BOOKING_REFERRAL, {
            referral_id: referralId,
            booking_id: bookingId,
        })
    }
}

export function useTrackAdditionalPaymenRequest() {
    return ({
        paymentRequestId,
        bookingId,
    }: {
        paymentRequestId: string
        bookingId: string
    }) => {
        trackEvent(EventName.ADDITIONAL_PAYMENT_REQUEST, {
            payment_request_id: paymentRequestId,
            booking_id: bookingId,
        })
    }
}

export function useTrackAvailability() {
    return ({
        resourceType,
        resourceId,
        outfitterId,
    }: {
        resourceType: string
        resourceId: string
        outfitterId: string
    }) => {
        trackEvent(EventName.AVAILABILITY, {
            resource_type: resourceType,
            resource_id: resourceId,
            outfitter_id: outfitterId,
        })
    }
}

export function useTrackExportPaymentsCsv() {
    return ({ outfitterId }: { outfitterId: string }) => {
        trackEvent(EventName.EXPORT_PAYMENTS_CSV, {
            outfitter_id: outfitterId,
        })
    }
}

export function useTrackExportRemainingPaymentsCsv() {
    return ({ outfitterId }: { outfitterId: string }) => {
        trackEvent(EventName.EXPORT_REMAINING_PAYMENTS_CSV, {
            outfitter_id: outfitterId,
        })
    }
}

export function useTrackCreateTeamMember() {
    return ({ outfitterId }: { outfitterId: string }) => {
        trackEvent(EventName.ADDED_TEAM_MEMBER, {
            outfitter_id: outfitterId,
        })
    }
}

export function useTrackCreateResource() {
    return ({
        outfitterId,
        resourceType,
    }: {
        outfitterId: string
        resourceType: ResourceType
    }) => {
        trackEvent(EventName.CREATE_RESOURCE, {
            outfitter_id: outfitterId,
            resource_type: resourceType,
        })
    }
}

export function useTrackCreateBookingInvite() {
    return ({
        bookingInviteId,
        outfitterId,
        listingId,
    }: {
        bookingInviteId: string
        outfitterId: string
        listingId: string
    }) => {
        trackEvent(EventName.CREATE_BOOKING_INVITE, {
            bookingInviteId,
            outfitter_id: outfitterId,
            listing_id: listingId,
        })
    }
}

export function useTrackDeclineBookingReferral() {
    return ({ referralId, reason }: { referralId: string; reason: string }) => {
        trackEvent(EventName.DECLINE_BOOKING_REFERRAL, {
            referral_id: referralId,
            reason,
        })
    }
}
