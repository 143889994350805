import { HUBSPOT_INLINE_EMBED_ELEMENT_ID } from "~config/constants"

export function openChatWidgetCore() {
    if (!window.HubSpotConversations.widget.status().loaded) {
        window.HubSpotConversations.widget.load()
    }
    window.HubSpotConversations.widget.open()
}

export function openChatWidget() {
    openChatWidgetCore()

    const widgetContainer = document.getElementById(
        HUBSPOT_INLINE_EMBED_ELEMENT_ID
    )

    if (widgetContainer) {
        widgetContainer.style.visibility = "visible"
    }
}

export function closeChatWidget() {
    const widgetContainer = document.getElementById(
        HUBSPOT_INLINE_EMBED_ELEMENT_ID
    )

    if (widgetContainer) {
        widgetContainer.style.visibility = "hidden"
    }

    window.HubSpotConversations.widget.close()
}
