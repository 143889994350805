import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Divider } from "@chakra-ui/react"
import { componentStyles, Box } from "@mallardbay/lib-react-components"

import { useSidebarItemGroups } from "~components/shared/sidebar/sidebar-items"
import OutfitterSelectGlobal from "~components/shared/outfitter-select/outfitter-select-global"

import SidebarItem from "./sidebar-item"

type SidebarContentProps = {
    readonly isCollapsed: boolean
}

export default function SidebarContent({
    isCollapsed: isSidebarCollapsed,
}: SidebarContentProps): React.ReactElement {
    const styles = useStyles()

    const sidebarItemGroups = useSidebarItemGroups()

    return (
        <>
            <Box style={styles.outfitterSelectContainer}>
                <OutfitterSelectGlobal />
            </Box>

            {sidebarItemGroups.map((group, groupIdx) => {
                return (
                    <Box key={groupIdx}>
                        {groupIdx !== 0 && <Divider key="divider" />}
                        <Box style={styles.container}>
                            {group.map((item) => (
                                <SidebarItem
                                    key={item.name}
                                    label={item.label}
                                    link={item.link}
                                    icon={item.icon}
                                    isSidebarCollapsed={isSidebarCollapsed}
                                    isPaywalled={item.isPaywalled}
                                    isExternalLink={item.isExternalLink}
                                />
                            ))}
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}

function useStyles() {
    return componentStyles({
        container: {
            paddingY: 3,
        },
        outfitterSelectContainer: {
            paddingX: 3,
            paddingTop: 5,
        },
        adminButton: {
            width: "100%",
            borderRadius: "none",
            justifyContent: "flex-start",
            paddingLeft: 7,
            fontWeight: 700,
        },
        panel: { padding: 0 },
        childrenContainer: {
            gap: 0,
            alignItems: "center",
        },
    })
}
