import React, { useContext, useState } from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Box,
    Text,
    Button,
    HStack,
    Input,
    Spacer,
    Highlight,
    Center,
    Spinner,
    Avatar,
    Tag,
    useColorModeValue,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal } from "@mallardbay/lib-react-components"

import { useDebounce } from "~utils/hooks/use-debounce"
import type { UserFieldsFragment } from "~graphql/generated/graphql"
import {
    useSearchUsersQuery,
    useStartImpersonationMutation,
    useStopImpersonationMutation,
} from "~graphql/generated/graphql"
import { UserContext } from "~config/context-providers/user-provider"
import ImpersonationBannerContent from "~components/shared/todo-lib-react-components/impersonation-banner/impersonation-banner-content"
import Divider from "~components/shared/todo-lib-react-components/divider"
import { useClearSelectedOutfitter } from "~utils/hooks/use-persisted-selected-global-outfitter"
import COPY from "~config/copy-constants"
import { navigateWithHardReload } from "~utils/helpers/navigation-helpers"

interface Props {
    readonly isOpen: boolean
    readonly onClose: () => void
}

// TODO: Move all styling to useStyles hook.

export default function ImpersonationModal({ isOpen, onClose }: Props) {
    const { user: currentUser, isImpersonating } = useContext(UserContext)

    const [query, setQuery] = useState("")
    const debouncedQuery = useDebounce(query, 500).trim()

    const { loading, data } = useSearchUsersQuery({
        variables: {
            text: debouncedQuery,
            page: 0,
            size: 8,
        },
        skip: !isOpen,
    })

    const handleClose = () => {
        setQuery("")
        onClose()
    }

    const users = [...(data?.searchUsers.results || [])].filter(
        (user) => user.id !== currentUser?.id
    )
    const total = data?.searchUsers.total || 0

    return (
        <Modal
            title={COPY.SWITCH_IMPERSONATION}
            isOpen={isOpen}
            onClose={handleClose}
            shouldAddPadding
        >
            <ImpersonationBannerContent
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            />
            <Divider />
            <HStack>
                <FontAwesomeIcon icon={faSearch} />
                <Input
                    variant="unstyled"
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder={`Search ${total} users`}
                />
            </HStack>
            <Divider />

            {isImpersonating && currentUser && (
                <UserImpersonationItem
                    query={query}
                    user={currentUser}
                    isViewing
                />
            )}
            {loading && (
                <Center h="120px">
                    <Spinner size="sm" />
                </Center>
            )}
            {!loading && users.length === 0 && (
                <Center h="120px" fontWeight="bold">
                    No users found
                </Center>
            )}
            {users.map((user) => (
                <UserImpersonationItem
                    key={user.id}
                    user={user}
                    query={query}
                />
            ))}
        </Modal>
    )
}

interface UserImpersonationItemProps {
    readonly user: UserFieldsFragment
    readonly query: string
    readonly isViewing?: boolean
}

function UserImpersonationItem({
    user,
    query,
    isViewing = false,
}: UserImpersonationItemProps) {
    const clearSelectedOutfitter = useClearSelectedOutfitter()

    const [starImpersonation, { loading: isStarting }] =
        useStartImpersonationMutation({
            variables: {
                actsAsUserId: user.id,
            },
            onCompleted: () => {
                navigateWithHardReload()
            },
        })

    const [stopImpersonation, { loading: isStopping }] =
        useStopImpersonationMutation({
            onCompleted: () => {
                navigateWithHardReload()
            },
        })

    const activeBgColor = useColorModeValue("brand.50", "brand.800")

    return (
        <HStack
            py={2}
            px={2}
            spacing={3}
            rounded="xl"
            bgColor={isViewing ? activeBgColor : "initial"}
        >
            <Avatar
                shadow="lg"
                src={user.avatar_url ?? ""}
                name={`${user.first_name} ${user.last_name}`}
            />
            <Box>
                <HStack>
                    <Text fontSize="15px" noOfLines={1} fontWeight="bold">
                        <Highlight query={query} styles={{ bg: "yellow.200" }}>
                            {`${user.first_name} ${user.last_name}`}
                        </Highlight>
                    </Text>
                    {isViewing && (
                        <Tag size="sm" colorScheme="brand" fontWeight="bold">
                            Viewing
                        </Tag>
                    )}
                </HStack>
                <Text noOfLines={1} fontSize="xs">
                    <Highlight query={query} styles={{ bg: "yellow.200" }}>
                        {user.email}
                    </Highlight>
                </Text>
            </Box>
            <Spacer />
            {isViewing ? (
                <Button
                    size="sm"
                    colorScheme="red"
                    isLoading={isStopping}
                    onClick={async () => {
                        await stopImpersonation()
                        clearSelectedOutfitter()
                    }}
                >
                    Exit
                </Button>
            ) : (
                <Button
                    size="sm"
                    isLoading={isStarting}
                    onClick={async () => {
                        await starImpersonation()
                        clearSelectedOutfitter()
                    }}
                >
                    View as
                </Button>
            )}
        </HStack>
    )
}
