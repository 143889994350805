import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
} from "@chakra-ui/react"

import TextLogo from "~components/shared/header/logo"
import SidebarContent from "~components/shared/sidebar/sidebar-content"
import SelectedOutfitterPermissionProvider from "~components/shared//permissions/permissions-provider"
import { usePageLayout } from "~config/context-providers/page-layout-provider"
import { TEST_IDS } from "~config/constants"

export default function SidebarDrawer() {
    const { isSidebarOpen, closeSidebar } = usePageLayout()

    return (
        <Drawer isOpen={isSidebarOpen} placement="left" onClose={closeSidebar}>
            <DrawerOverlay>
                <DrawerContent
                    mt={4}
                    mb={4}
                    ml={4}
                    maxW="280px"
                    rounded="2xl"
                    data-testid={TEST_IDS.SIDEBAR_DRAWER}
                >
                    <DrawerCloseButton top={3} right={2} />
                    <DrawerHeader>
                        <TextLogo py={1} width="168px" />
                    </DrawerHeader>
                    <DrawerBody pl={0} pr={0} pt={0}>
                        <SelectedOutfitterPermissionProvider>
                            <SidebarContent isCollapsed={false} />
                        </SelectedOutfitterPermissionProvider>
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    )
}
