import React from "react"
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { TEST_IDS } from "~config/constants"

interface Props {
    readonly size?: number
}

export default function PaywallIcon({ size = 15 }: Props) {
    return (
        <FontAwesomeIcon
            data-testid={TEST_IDS.PAYWALL_ICON}
            icon={faLock}
            fontSize={size}
        />
    )
}
