import {
    namedOperations,
    useUpdateOutfitterMutation,
} from "~graphql/generated/graphql"
import type { UpdateOutfitterInput } from "~graphql/generated/graphql"
import COPY_CONSTANTS from "~config/copy-constants"
import { useTrackEditOutfitter } from "~utils/event-tracking/event-tracking"

import { useToastFeedback } from "./use-toast-feedback"

export function useUpdateOutfitter({
    outfitterId,
    onCompleted,
    updatedSection,
}: {
    outfitterId: string
    onCompleted?: () => void
    updatedSection: string
}) {
    const { onCompleted: onCompletedToast, onError } = useToastFeedback(
        COPY_CONSTANTS.OUTFITTER_UPDATED_SUCCESSFULLY
    )

    const trackEditOutfitter = useTrackEditOutfitter()

    const [updateOutfitterMutation, { loading }] = useUpdateOutfitterMutation({
        refetchQueries: [
            namedOperations.Query.outfittersByOwner,
            namedOperations.Query.outfitter,
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
            onCompletedToast()
            onCompleted?.()
            trackEditOutfitter({
                outfitterId,
                updatedSection,
            })
        },
        onError,
    })

    const updateOutfitter = (input: UpdateOutfitterInput) => {
        return updateOutfitterMutation({
            variables: { id: outfitterId, data: input },
        })
    }

    return { updateOutfitter, isloading: loading }
}
