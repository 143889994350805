import type { Sizes, StyleProps } from "@mallardbay/lib-react-components"
import React from "react"
import { Avatar as AvatarOg } from "@chakra-ui/react"

interface Props {
    readonly src?: string
    readonly title?: string
    readonly name?: string
    readonly size?: Sizes
    readonly style?: StyleProps
}

export default function Avatar({ src, title, name, size, style }: Props) {
    return (
        <AvatarOg src={src} title={title} name={name} size={size} sx={style} />
    )
}
