import React, { useContext } from "react"
import { useMatch } from "@reach/router"

import { useStopImpersonationMutation } from "~graphql/generated/graphql"
import { UserContext } from "~config/context-providers/user-provider"
import useDisclosure from "~components/shared/todo-lib-react-components/use-disclosure"
import ImpersonationModal from "~components/shared/auth/impersonation-modal"
import ImpersonationBanner from "~components/shared/todo-lib-react-components/impersonation-banner/impersonation-banner"
import { navigateWithHardReload } from "~utils/helpers/navigation-helpers"

export default function ImpersonationBox() {
    const { user, isImpersonating } = useContext(UserContext)
    const {
        isOpen: isModalOpen,
        onOpen: openModal,
        onClose: closeModal,
    } = useDisclosure()

    const matchAdmin = useMatch("/admin/*")
    const matchReporting = useMatch("/reporting/*")

    const [stopImpersonation, { loading: isLoading }] =
        useStopImpersonationMutation({
            onCompleted: () => {
                navigateWithHardReload()
            },
        })

    // Hide if we're on an admin only page, no user, or not impersonating.
    if (matchAdmin || matchReporting || !user || !isImpersonating) return null

    return (
        <>
            <ImpersonationBanner
                onSwitchImpersonationClick={openModal}
                onStopImpersonatingClick={stopImpersonation}
                isLoading={isLoading}
            />
            <ImpersonationModal isOpen={isModalOpen} onClose={closeModal} />
        </>
    )
}
