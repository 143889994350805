import { useMemo } from "react"
import type { ApolloError, WatchQueryFetchPolicy } from "@apollo/client"

import {
    useOutfitterQuery,
    useRemoveOutfitterMutation,
    TeamMemberRole,
    namedOperations,
    useOutfittersByOwnerQuery,
} from "~graphql/generated/graphql"
import type {
    OutfitterFieldsFragment,
    MinimalOutfitterFieldsFragment,
    UserFieldsFragment,
    RemoveOutfitterMutation,
} from "~graphql/generated/graphql"
import { useUser } from "~utils/hooks/use-user"
import { useToastFeedback } from "~utils/hooks/use-toast-feedback"
import COPY from "~config/copy-constants"
import { filterApprovedOutfitters } from "~utils/helpers/helpers"

interface Props {
    readonly id?: string | null
    readonly fetchPolicy?: WatchQueryFetchPolicy
}

export default function useOutfitter({ id, fetchPolicy = "no-cache" }: Props) {
    const { user, isAdminNotImpersonating } = useUser()
    const { onError } = useToastFeedback()

    const {
        data,
        loading: isLoading,
        error,
    } = useOutfitterQuery({
        onError,
        variables: { id: id ?? "" },
        fetchPolicy,
        skip: !id,
    })

    const outfitter = data?.outfitter ?? null
    const isOutfitterOwner = doesUserHaveOutfitterOwnerPermission({
        outfitter,
        user,
        isAdminNotImpersonating,
    })

    return { outfitter, isLoading, error, isOutfitterOwner }
}

export function useDeleteOutfitter({
    id,
    onSuccessCallback,
    onErrorCallback,
}: {
    id: string
    onSuccessCallback?: (data: RemoveOutfitterMutation) => void
    onErrorCallback?: (error: ApolloError) => void
}) {
    const { onCompleted: onCompletedToast, onError: onErrorToast } =
        useToastFeedback(
            COPY.DELETE_OUTFITTER_SUCCESS,
            COPY.DELETE_OUTFITTER_ERROR
        )

    return useRemoveOutfitterMutation({
        variables: { id },
        onCompleted: (data) => {
            onCompletedToast()
            onSuccessCallback?.(data)
        },
        onError: (error) => {
            onErrorToast()
            onErrorCallback?.(error)
        },
        refetchQueries: [
            namedOperations.Query.outfittersByOwner,
            namedOperations.Query.searchOutfitters,
        ],
        awaitRefetchQueries: true,
    })
}

function doesUserHaveOutfitterOwnerPermission({
    outfitter,
    user,
    isAdminNotImpersonating,
}: {
    outfitter: OutfitterFieldsFragment | null
    user: UserFieldsFragment | null | undefined
    isAdminNotImpersonating: boolean
}) {
    // If user is an admin, but impersonating, do not
    // consider them admins. This gives a more accurate
    // picture when impersonating.
    if (isAdminNotImpersonating) return true
    if (!outfitter || !user) return false

    // Currently, my_roles seems to be returning other user's roles, so
    // check user.id as well
    const isOutfitterOwner = outfitter.owner.id === user.id
    const hasOwnerRole = !!outfitter.my_roles.find((myRole) => {
        const ownerRoles = [TeamMemberRole.CoOwner, TeamMemberRole.Owner]

        return ownerRoles.includes(myRole.role) && myRole.user?.id === user.id
    })

    return isOutfitterOwner || hasOwnerRole
}

export function useMyOutfitters({
    onCompleted,
    shouldSkip,
}: {
    onCompleted?: (outfitters: MinimalOutfitterFieldsFragment[]) => void
    shouldSkip?: boolean
} = {}) {
    const { user, isLoading: isUserLoading } = useUser()

    const {
        data,
        error,
        loading: isLoading,
    } = useOutfittersByOwnerQuery({
        variables: { id: user?.id ?? "" },
        skip: shouldSkip || !user?.id,
        onCompleted: (response) => onCompleted?.(response.outfittersByOwner),
    })

    const outfitters = data?.outfittersByOwner ?? []
    const approvedOutfitters = useMemo(
        () => filterApprovedOutfitters(outfitters),
        [outfitters]
    )

    return {
        error,
        outfitters,
        approvedOutfitters,
        defaultOutfitter: outfitters[0] ?? null,
        isLoading: isUserLoading || isLoading,
    }
}
