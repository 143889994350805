import React from "react"

import { useStepOutfitter } from "~components/self-onboard/steps/outfitter-setup/setup-helpers"
import AuthenticatingLoader from "~components/shared/loader/authenticating-loader"
import Redirect from "~components/shared/redirect"
import { ROUTES } from "~config/constants"
import { SelfOnboardingStepType } from "~graphql/generated/graphql"
import { useLocation } from "~utils/hooks/use-location"

export default function SelfOnboardRedirect({
    children,
}: {
    readonly children: React.ReactNode
}) {
    const location = useLocation()
    const { outfitter, isOutfitterLoading } = useStepOutfitter()

    const shouldRedirect =
        // outfitters that were created before self onboarding will have this set to null
        // we do not want to redirect these outfitters
        outfitter?.current_self_onboard_step &&
        outfitter.current_self_onboard_step !==
            SelfOnboardingStepType.FinishedSetup &&
        // need to check current path otherwise there can be a state where loading is done and outfitter is still null so it re-directs infinite times
        !location.pathname.includes(ROUTES.SELF_ONBOARD)

    if (isOutfitterLoading) {
        return <AuthenticatingLoader />
    }

    if (shouldRedirect) {
        return <Redirect to={ROUTES.SELF_ONBOARD} />
    }

    // we need to wrap children in a fragment to avoid SSR issues
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
}
