import type { StripeAccountInfo } from "~graphql/generated/graphql"
import { useStripeAccountInfoQuery } from "~graphql/generated/graphql"
import { useAuth } from "~utils/hooks/use-auth"
import { getGettingStartedUrlWithDomain } from "~utils/helpers/navigation-helpers"

export function useStripeAccountInfo() {
    const url = getGettingStartedUrlWithDomain()

    return useStripeAccountInfoCore(url)
}

export function useStripeAccountInfoCore(
    url?: string,
    onCompleted?: (stripeAccountInfo?: StripeAccountInfo) => void
) {
    const { isAuthenticated } = useAuth()

    const {
        data,
        loading: isLoading,
        error,
    } = useStripeAccountInfoQuery({
        skip: !isAuthenticated,
        variables: {
            onboardLinkUrls: {
                refreshUrl: url ?? "",
                returnUrl: url ?? "",
            },
        },
        onCompleted: (response) => {
            onCompleted?.(response.stripeAccountInfo)
        },
        fetchPolicy: "network-only",
    })

    const stripeAccountInfo = data?.stripeAccountInfo ?? null

    return { stripeAccountInfo, isLoading, error }
}
