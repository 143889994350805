import { useEffect } from "react"
import { captureException } from "@sentry/react"
import {
    initAnalytics,
    trackEvent as trackEventGeneric,
} from "@mallardbay/lib-shared-helpers"
import { globalHistory } from "@reach/router"

import { useUser } from "~config/context-providers/user-provider"
import { useGetUserTrackingDataQuery } from "~graphql/generated/graphql"

const CUSTOM_PAGE_VIEW = "custom_page_view"

let userId: string | null = null

export default function useAnalytics() {
    const { user } = useUser()
    const { loading: isLoading, data } = useGetUserTrackingDataQuery({
        onError: captureException,
    })

    const userTrackingProps = data?.userTrackingData.props || []

    useEffect(() => {
        const isReady = user?.id && !isLoading
        if (!isReady) return

        setUserId(user.id)
        initAnalytics({
            userId: user.id,
            userTrackingProps,
            onError: (err) => handleError(err),
        })
    }, [user, isLoading, userTrackingProps])

    // Custom page view tracking
    useEffect(() => {
        const unsubscribe = globalHistory.listen(({ location }) => {
            trackEvent(CUSTOM_PAGE_VIEW, {
                pathname: location.pathname,
                search: location.search,
                host: location.host,
            })
        })

        return () => unsubscribe()
    }, [])
}

// Exported for tests
// We do this so we can use it outside of hooks easily
function setUserId(userIdLocal?: string) {
    userId = userIdLocal || null
}

// Use this over the core `trackEvent` from `lib-shared-helpers`
export function trackEvent(
    eventName: string,
    eventParams: Record<string, unknown>
) {
    const paramsToUse = {
        ...eventParams,
        userId,
    }
    trackEventGeneric({
        eventName,
        eventParams: paramsToUse,
        onError: handleError,
    })
}

// Exported for tests
export function handleError(error: Error) {
    if (!process.env.IS_LOCAL) {
        captureException(error)
    } else {
        console.error(error)
    }
}
