import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { IconButton } from "@chakra-ui/react"
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type Props = {
    readonly onClick: () => void
}

export default function SidebarDrawerButton({ onClick }: Props) {
    return (
        <IconButton
            rounded="full"
            variant="outline"
            aria-label="menu-button"
            icon={<FontAwesomeIcon icon={faBars} fontSize={20} />}
            onClick={onClick}
            display={["flex", "flex", "none"]}
        />
    )
}
